import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from '../Hook/useSnackbar'
import SyncIcon from '@mui/icons-material/Sync'
function Footer(props) {
    const ggSheetAPI = process.env.REACT_APP_GG_SHEET_API
    const showSnackbar = useSnackbar()
    const { isChangeLanguage, handleButtonClick } = props
    const [emailSubscriber, setEmailSubscriber] = useState('')
    console.log(handleButtonClick)
    const [loadingButton, setLoadingButton] = useState(false)
    const isValidEmail = (input) => {
        // Biểu thức chính quy để kiểm tra địa chỉ email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(input)
    }
    const isDisabled = !isValidEmail(emailSubscriber)

    const handleSubmitSubscriber = async () => {
        try {
            setLoadingButton(true)
            const response = await axios.post(`${ggSheetAPI}/tabs/email subscription`, {
                email: emailSubscriber,
            })
            if (response.status == 200) {
                setEmailSubscriber('')
                setLoadingButton(false)
                showSnackbar({
                    severity: 'success',
                    children: isChangeLanguage == 'ENG' ? 'Subscriber Successfully' : 'Đăng ký thành công',
                })
            } else {
                setLoadingButton(false)
            }
        } catch (error) {
            setLoadingButton(false)
        }
    }
    return (
        <div className="font-lexend   md:px-0 sm:h-[554px] ">
            <div className=" md:block flex sm:items-center sm:text-left text-center sm:justify-center  pb-4 sm:h-[473px]  bg-[#FF7559] text-[#FFFFFF]">
                <div className="2xl:mx-[40px] xl:mx-[30px] lg:mx-[22px] md:mx-[16px] sm:mx-[8px] mx-4 sm:w-auto w-full">
                    <h2 className="pt-12 sm:pt-[75px] text-xl sm:text-[40px] font-extrabold ">Otto.</h2>
                    <p className=" sm:mt-[10px] text-[8px] sm:text-sm">
                        {isChangeLanguage == 'ENG'
                            ? `Your travel companion, Anywhere, Anytime.`
                            : `Người bạn đồng hành trong mỗi chuyến đi.`}
                    </p>
                    <p className="mt-2 sm:mt-4 md:mt-[85px] text-xl sm:text-2xl font-bold">
                        {isChangeLanguage == 'ENG' ? `Subscribe with Us` : `Đăng ký với chúng tôi`}
                    </p>
                    <p className=" text-[8px] sm:text-sm">
                        {isChangeLanguage == 'ENG'
                            ? `Hear about our upcoming product releases.`
                            : `Đăng kí để nhận những cập nhật mới nhất vê sản phẩm.`}
                    </p>
                    <div className="mt-1 sm:mt-7  md:flex  gap-[5px]">
                        <input
                            value={emailSubscriber}
                            placeholder= {isChangeLanguage == 'ENG'
                            ? "Please enter your email here"
                            : `Vui lòng điền email của bạn ở đây`}
                            onChange={(e) => setEmailSubscriber(e.target.value)}
                            className="my-2 md:m-0 w-[200px] sm:w-full h-8 lg:w-[425px] sm:h-[54px] outline-none border-[1px] text-black
                             border-[#FFFFFF] bg-[#FFFFFF]  px-4 text-[10px] sm:text-xl rounded-[4px] sm:rounded-xl"
                        />
                        <button
                            disabled={isDisabled}
                            onClick={handleSubmitSubscriber}
                            className={`my-2 p-2 px-4 sm:p-4 md:m-0 sm:text-left text-center mx-auto sm:h-[54px] h-6  lg:w-[160px] flex items-center 
                            justify-center text-[10px] sm:text-base font-bold uppercase text-[#FFFFFF] ${isDisabled ? `bg-[#808080]` : `bg-black`} 
                             rounded-[4px] sm:rounded-xl`}
                        >
                            {loadingButton == true ? (
                                <SyncIcon style={{ fontSize: '2vw' }} className="animate-spin h-12 w-12" />
                            ) : isChangeLanguage == 'ENG' ? (
                                `Subscribe`
                            ) : (
                                `Đăng kí`
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div className="sm:h-[82px] bg-black md:flex md:items-center ">
                <div className="w-full">
                    <div className="2xl:mx-[40px] xl:mx-[30px] lg:mx-[22px] md:mx-[16px] sm:mx-[8px]  md:text-left text-center pb-2 sm:pt-4  md:p-0 md:flex text-white mx-auto ">
                        <p className="text-[10px] sm:text-base font-lexend font-medium  pt-1 sm:pt-0 ">
                            {isChangeLanguage == 'ENG'
                                ? `Copyright, Otto(TM) 2023. All Rights Reserved`
                                : `Bản quyền, Otto(TM) 2023. Mọi quyền được bảo lưu`}
                        </p>

                        <div className="flex items-center text-center justify-center ml-auto">
                            <Link
                                to="/privacy"
                                className="text-[10px] sm:text-base font-lexend font-medium hover:text-[#F5ECE9]"
                            >
                                {isChangeLanguage == 'ENG' ? `Privacy Notice` : `Chính sách bảo mật`}
                            </Link>
                            <strong className="text-xl mx-1 -mt-3">.</strong>
                            {handleButtonClick == undefined ? (
                                <Link
                                    to="/#support"
                                    className="text-[10px] sm:text-base font-lexend font-medium cursor-pointer hover:text-[#F5ECE9]"
                                >
                                    {isChangeLanguage == 'ENG' ? `Support` : `Hỗ trợ`}
                                </Link>
                            ) : (
                                <p
                                    onClick={handleButtonClick}
                                    className="text-[10px] sm:text-base font-lexend font-medium cursor-pointer hover:text-[#F5ECE9]"
                                >
                                    {isChangeLanguage == 'ENG' ? `Support` : `Hỗ trợ`}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
