import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import imgaeHeader from '../Img/MaskGroup.png'
import imgaeBanner from '../Img/Banner.png'
import iconLocation from '../Img/location_icon.png'
import imageRectangle from '../Img/BackgrondRectangle.png'
import imageFrame from '../Img/Frame.png'
import videoSrc from '../Videos/videos.mov'
import Footer from '../Component/Footer'
import axios from 'axios'
import { useSnackbar } from '../Hook/useSnackbar'
import Loading from '../Component/Loading'
import SyncIcon from '@mui/icons-material/Sync';
import { useLocation } from 'react-router-dom'

const placeholderStyle = {
    '::placeholder': {
        color: '#FFFFFF',
    },
}

function Home(props) {
    const ggSheetAPI = process.env.REACT_APP_GG_SHEET_API
    const [loading, setLoading] = useState(true)
    const showSnackbar = useSnackbar()
    const [phoneNumber, setphoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [loadingButton, setLoadingButton] = useState(false)
    const [message, setMessage] = useState('')
    const [selectedCheckbox, setSelectedCheckbox] = useState(null)
    const contentRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => (location.hash === '#support' ? handleButtonClick() : ``), 1000)
    }, [location.hash, contentRef])

    const handleButtonClick = () => {
        if (contentRef && contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    const handleCheckboxChange = (checkboxName) => {
        if (selectedCheckbox === checkboxName) {
            // Nếu checkbox đã chọn trước đó là checkbox hiện tại, bỏ chọn
            setSelectedCheckbox(null)
        } else {
            // Chọn checkbox mới và bỏ chọn checkbox cũ (nếu có)
            setSelectedCheckbox(checkboxName)
        }
    }
    const isValidPhoneNumber = (input) => {
        const phoneRegex = /^[0-9]{10}$/
        return phoneRegex.test(input)
    }

    const isDisabled =
        phoneNumber.trim() === '' ||
        message.trim() === '' ||
        !isValidPhoneNumber(phoneNumber) ||
        selectedCheckbox === null

    const { isChangeLanguage } = props
    const handleSubmitCustomer = async () => {
        try {
            setLoadingButton(true)
            const response = await axios.post(`${ggSheetAPI}/tabs/contact form`, {
                phoneNumber: phoneNumber,
                email: email,
                message: message,
                lookingFor:
                    selectedCheckbox == 'checkbox1'
                        ? `Looking for ways to boost car booking service`
                        : selectedCheckbox == 'checkbox2'
                          ? `Earning extra income through driving`
                          : selectedCheckbox == 'checkbox3'
                            ? `Looking to book business or personal trips`
                            : ``,
            })
            if (response.status == 200) {
                setSelectedCheckbox(null)
                setEmail('')
                setphoneNumber('')
                setMessage('')
                setLoadingButton(false)
                showSnackbar({
                    severity: 'success',
                    children: isChangeLanguage == 'ENG' ? 'Request Successfully' : 'Gửi thành công',
                })
            } else {
                setLoadingButton(false)
            }
        } catch (error) {
            setLoadingButton(false)
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 0)

        return () => clearTimeout(timer)
    }, [])
    return (
        <div>
            <div className="">
                <div className=" mt-4 sm:mt-[38px]  xl:grid grid-cols-2 2xl:mx-[40px] xl:mx-[30px] lg:mx-[22px] md:mx-[16px] sm:mx-[8px] px-2 md:px-0">
                    <div className="z-10">
                        <div className="font-lexend font-extrabold text-[26px] sm:text-[28px] md:text-[54px] lg:text-[68px]  xl:text-[80px] xl:w-[1000px] ">
                            {isChangeLanguage == 'ENG' ? (
                                <p>
                                    Your travel companion, <br /> Anywhere, Anytime
                                </p>
                            ) : (
                                <p>
                                    Người bạn đồng hành <br /> trong mỗi chuyến đi
                                </p>
                            )}
                        </div>
                        <p className=" mt-2 sm:mt-5 font-lexend-regular  text-[8px] md:text-[12px] lg:text-[16px]  ">
                            {isChangeLanguage == 'ENG'
                                ? `Embark on Effortless Travels with Our Exclusive App – Book Your Ride Now `
                                : `Bắt đầu chuyến đi của bạn một cách dễ dàng và tiện lợi với ứng dụng Otto -`}
                        </p>
                        <p className="font-lexend-regular  text-[8px] md:text-[12px] lg:text-[16px]  ">
                            {isChangeLanguage == 'ENG'
                                ? ` and Experience the Ultimate in Comfort and Convenience!`
                                : `Nơi mà mọi hành trình trở nên đơn giản và thoải mái!`}
                        </p>
                        <button
                            className=" mt-2 sm:mt-5 uppercase bg-black text-white rounded-[49px]  font-lexend-regular 
                            px-4 py-2  text-[12px]  sm:text-[16px] md:text-[18px]  lg:w-[190px]  lg:h-[57px] "
                            onClick={handleButtonClick}
                        >
                            {isChangeLanguage == 'ENG' ? `Learn more` : `Tìm hiểu thêm`}
                        </button>
                        <div className="absolute right-0 pt-2 sm:top-[15%] md:top-[20%] lg:top-[24%] -z-10">
                            {/* <div className="relative ">
                                    <img
                                        src={imgaeHeader}
                                        className="object-cover -mt-[14px] sm:-mt-1 w-[215px]  md:w-[520px] lg:w-[670px] xl:w-[800px] 2xl:w-[910px]"
                                        alt="Header"
                                    /> */}
                            <video
                                className=" object-cover -mt-[6px] sm:mt-[18px] w-[320px] sm:w-[480px] 
                                     md:mt-0 md:w-[760px] lg:w-[910px] xl:w-[1040px] 2xl:w-[1150px]"
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source src={videoSrc} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {/* </div> */}
                            <button
                                className="absolute bottom-0 ml-[50px] sm:ml-[70px] md:ml-[120px] lg:ml-[140px] xl:ml-[200px]  font-lexend-bold font-bold
                             bg-[#FF7559] rounded-full h-[80px] w-[80px] sm:gap-2 sm:h-[120px] sm:w-[120px] md:h-[147px]
                              md:w-[147px] lg:h-[182px] lg:w-[182px] xl:h-[240px] xl:w-[240px] uppercase "
                            >
                                <p className="text-[10px] sm:text-sm lg:text-[24px] xl:text-[32px]  lg:mb-3 ">
                                    {isChangeLanguage === 'ENG' ? 'Hello' : 'Xin chào'}
                                </p>
                                <p className="text-[10px] sm:text-sm lg:text-[24px] xl:text-[32px]">
                                    {isChangeLanguage === 'ENG' ? ' VietNam' : 'Việt Nam'}
                                </p>
                            </button>
                        </div>
                    </div>
                </div>

                <div className=" mt-56 sm:mt-[240px] md:mt-[300px] lg:mt-[500px] xl:mt-[520px]  flex xl:h-[660px] flex-col items-center justify-center w-full relative">
                    <img src={imgaeBanner} className=" object-cover   xl:w-full xl:h-full -z-10 " alt="Banner" />
                    <img
                        src={iconLocation}
                        className="top-[25%] xl:top-52 h-6 w-6 sm:h-8 sm:w-8 md:h-[66px] md:w-[66px] -z-10 absolute"
                        alt="Location_Icon"
                    />
                    <p className="px-2 md:px-0 m-0 text-white text-center font-lexend-semibold text-sm sm:text-xl lg:text-3xl xl:text-[40px] font-semibold absolute">
                        {isChangeLanguage == 'ENG'
                            ? ` Coming soon to HCM City, Vietnam in 2024`
                            : `Có mặt tại Thành phố Hồ Chí Minh, Việt Nam vào năm 2024.`}
                    </p>
                </div>
                <div className="relative sm:block sm:mb-0 mb-12  sm:h-[1300px]  ">
                    <img src={imageRectangle} className="w-full -mt-4 sm:-mt-12" alt="Rectangle" />
                    {/* <img
                            src={imageFrame}
                            className="absolute z-10 -mt-9 sm:-mt-28 lg:-mt-52  w-28 sm:w-[261px]  lg:w-[461px] lg:h-[649px] "
                            alt="Rectangle"
                        /> */}
                    <button
                        className="absolute left-12 lg:left-44 -mt-[60px] md:-mt-[130px] lg:-mt-[240px]
                         bg-[#F5ECE9] rounded-full h-10 w-10 sm:h-[72px] sm:w-[72px] lg:h-[102px] lg:w-[102px]  "
                    ></button>
                    <button
                        className="absolute  left-16 sm:left-32 lg:left-64 z-1   bg-[#E7F25A] 
                         rounded-full h-20 w-20 sm:h-[180px] sm:w-[180px] lg:h-[276px] lg:w-[276px]  "
                    ></button>
                    <button
                        className="absolute  mt-8 sm:mt-16 lg:mt-24 -left-20  sm:-left-28 lg:-left-48 z-1  bg-[#000000] 
                        rounded-full h-32 w-32 sm:h-[224px] sm:w-[224px] lg:h-[364px] lg:w-[364px]  "
                    ></button>
                    <div
                        id="toScroll"
                        ref={contentRef}
                        className=" z-2  bg-[#000000] top-[15%]  w-auto h-auto  sm:h-auto   flex items-center justify-center
                              mx-12 relative  sm:absolute  right-0 xl:right-10 sm:top-56 rounded-[12px] sm:rounded-[36px]  px-5 py-[18px] sm:px-24 sm:py-10 lg:px-[65px] lg:py-[40px]"
                    >
                        <div className=" ">
                            <p className="font-lexend font-extrabold text-lg sm:text-3xl lg:text-[52px] text-[#FF7559] ">
                                {isChangeLanguage == 'ENG' ? `Get in touch with us` : `Liên hệ với chúng tôi`}
                            </p>

                            <div className="font-lexend sm:mt-4">
                                <p className="mb-3 sm:mt-4 sm:mb-9 font-lexend text-[8px] sm:text-base text-[#FFFFFF]">
                                    {isChangeLanguage == 'ENG'
                                        ? ``
                                        : `Cùng đồng hành với chúng tôi  trong quá trình hoàn thiện sản phẩm nhé`}
                                </p>
                                <p className="font-semibold text-[10px] sm:text-base text-[#FFFFFF]">
                                    {isChangeLanguage == 'ENG'
                                        ? ` What are you looking for?`
                                        : `Hãy cho chúng tôi biết nhu cầu của bạn`}
                                </p>

                                <label className="flex items-center cursor-pointer mt-[8px] sm:mt-5 text-[#FFFFFF]">
                                    <input
                                        type="checkbox"
                                        className="hidden"
                                        checked={selectedCheckbox === 'checkbox1'}
                                        onChange={() => handleCheckboxChange('checkbox1')}
                                    />
                                    <div
                                        className={` w-2 h-2 sm:w-5 sm:h-5  border rounded-full border-gray-400 flex items-center 
                                            justify-center ${selectedCheckbox === 'checkbox1' ? 'bg-[#FF7559] border-[#FF7559]' : 'bg-white'}`}
                                    >
                                        {selectedCheckbox === 'checkbox1' && (
                                            <svg
                                                className="w-4 h-4 text-white"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        )}
                                    </div>
                                    <span className=" ml-[2px] sm:ml-2 text-[10px] sm:text-base font-lexend ">
                                        {isChangeLanguage == 'ENG'
                                            ? `Looking for ways to boost car booking service`
                                            : `Tìm kiếm cách tăng doanh thu dịch vụ đặt xe, cho thuê xe`}
                                    </span>
                                </label>
                                <label className="flex items-center mt-[8px] cursor-pointer sm:mt-5 text-[#FFFFFF]">
                                    <input
                                        type="checkbox"
                                        className="hidden"
                                        checked={selectedCheckbox === 'checkbox2'}
                                        onChange={() => handleCheckboxChange('checkbox2')}
                                    />
                                    <div
                                        className={` w-2 h-2 sm:w-5 sm:h-5  border rounded-full border-gray-400 flex items-center 
                                            justify-center ${selectedCheckbox === 'checkbox2' ? 'bg-[#FF7559] border-[#FF7559]' : 'bg-white'}`}
                                    >
                                        {selectedCheckbox === 'checkbox2' && (
                                            <svg
                                                className="w-4 h-4 text-white"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        )}
                                    </div>
                                    <span className=" ml-[2px] sm:ml-2 text-[10px] sm:text-base font-lexend ">
                                        {isChangeLanguage == 'ENG'
                                            ? `Earning extra income through driving`
                                            : `Kiếm thêm thu nhập qua công việc lái xe`}
                                    </span>
                                </label>
                                <label className="flex items-center   mt-[8px]  cursor-pointer sm:mt-5 text-[#FFFFFF]">
                                    <input
                                        type="checkbox"
                                        className="hidden"
                                        checked={selectedCheckbox === 'checkbox3'}
                                        onChange={() => handleCheckboxChange('checkbox3')}
                                    />
                                    <div
                                        className={` w-2 h-2 sm:w-5 sm:h-5  border rounded-full border-gray-400 flex items-center 
                                            justify-center ${selectedCheckbox === 'checkbox3' ? 'bg-[#FF7559] border-[#FF7559]' : 'bg-white'}`}
                                    >
                                        {selectedCheckbox === 'checkbox3' && (
                                            <svg
                                                className="w-4 h-4 text-white"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        )}
                                    </div>
                                    <span className=" ml-[2px] sm:ml-2 text-[10px] sm:text-base font-lexend ">
                                        {isChangeLanguage == 'ENG'
                                            ? `Looking to book business or personal trips`
                                            : `Tìm kiếm dịch vụ đặt xe cho chuyến đi công tác hoặc cá nhân`}
                                    </span>
                                </label>
                                <p className="font-semibold sm:mt-[30px] text-[10px] sm:text-base mt-3 text-[#FFFFFF]">
                                    {isChangeLanguage == 'ENG' ? `Message` : `Gửi lời nhắn cho chúng tôi`}
                                </p>
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="mt-[4px] sm:mt-3 w-full h-20 sm:h-[149px] 
                                        rounded-sm sm:rounded-md bg-[#FFFFFF] outline-none px-1 py-1 sm:px-6 sm:py-[17px] 
                                        font-lexend font-medium text-[8px] sm:text-base   "
                                    style={placeholderStyle}
                                    placeholder={
                                        isChangeLanguage == 'ENG'
                                            ? 'Let us know how we can help..'
                                            : 'Hãy cho chúng tôi biết suy nghĩ của bạn..'
                                    }
                                />
                                <p className="font-semibold  mt-3 text-[10px] sm:text-base sm:mt-[28px] text-[#FFFFFF]">
                                    {isChangeLanguage == 'ENG' ? `Contract` : `Thông tin liên hệ`}
                                </p>
                                <p className="font-semibold text-[10px] mt-1 sm:text-xs sm:mt-2 text-[#FFFFFF]">
                                    {isChangeLanguage == 'ENG' ? `Let us know how you'd like to be contacted.` : ``}
                                </p>
                                <div className="sm:mt-5 mt-3 grid grid-cols-2 gap-[18px] ">
                                    <div className="font-semibold text-[10px] sm:text-xs sm:mt-2 ">
                                        <p className="text-[#FFFFFF]">
                                            {isChangeLanguage == 'ENG' ? `Phone number (*)` : `Số điện thoại (*)`}
                                        </p>
                                        <input
                                            value={phoneNumber}
                                            onChange={(e) => setphoneNumber(e.target.value)}
                                            className="mt-1 sm:mt-3 w-full h-6  sm:h-[45px] rounded-sm sm:rounded-md bg-[#FFFFFF] outline-none px-2 text-black"
                                        />
                                    </div>
                                    <div className="font-semibold text-[10px] sm:text-xs sm:mt-2  ">
                                        <p className="text-[#FFFFFF]">Email</p>
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="sm:mt-3 mt-1 w-full h-6  sm:h-[45px] rounded-sm sm:rounded-md bg-[#FFFFFF] outline-none px-2 text-black"
                                        />
                                    </div>
                                </div>
                                <p className="font-lexend-regular mt-2 text-[10px] sm:text-xs sm:mt-[14px] ">
                                    {isChangeLanguage == 'ENG' ? `` : ` Cho chúng tôi biết làm sao để liên hệ với bạn`}
                                </p>
                                <button
                                    disabled={isDisabled}
                                    onClick={handleSubmitCustomer}
                                    className={`p-2  float-right  uppercase font-lexend-semibold ${isDisabled ? 'bg-[#FFAC9C]' : 'bg-[#FF7559]'}
                                        text-white mt-2 sm:mt-5  font-normal  rounded-[49px] py-1 sm:py-4 flex items-center 
                                        justify-center  text-[10px] md:text-[18px] w-[140px]  md:w-[265px]  md:h-[57px] md:leading-[57px] `}
                                >
                                    {loadingButton == true ? (
                                          <SyncIcon style={{ fontSize: '2vw' }} className="animate-spin h-12 w-12"  />
                                         
                                    ) : isChangeLanguage == 'ENG' ? (
                                        `Send your messsage`
                                    ) : (
                                        `Gửi`
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer isChangeLanguage={isChangeLanguage} handleButtonClick={handleButtonClick} />
            </div>
        </div>
    )
}

export default Home
