import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logoHome from '../Img/Otto-logo-coral.jpg'

function Navbar(props) {
    const { isChangeLanguage, callbackNavbar } = props
    const handleChangeLanguageVNG = () => {
        callbackNavbar('ENG')
        props.callbackNavbar('ENG')
    }
    const handleChangeLanguageENG = () => {
        callbackNavbar('VNG')
        props.callbackNavbar('VNG')
    }
    return (
        <div className="flex 2xl:mx-[40px] xl:mx-[30px] lg:mx-[22px] md:mx-[16px] sm:mx-[8px] mt-2 sm:mt-6  px-2 md:px-0">
            <Link to="/" className="font-lexend-extrabold  text-xl h-[52px] leading-[52px]">
                <img src={logoHome} className='h-8 sm:h-10 xl:h-[52px] ' />
            </Link>
            <div className="ml-auto">
                <button className=" h-8 w-20 sm:h-[40px] sm:w-[110px] xl:w-[134px] xl:h-[52px] border-[1px] rounded-3xl border-[#000000]">
                    <div className="flex px-[1px]">
                        <p
                            onClick={handleChangeLanguageVNG}
                            className={`z-10 m-[1px] xl:m-0  xl:px-[1px] leading-7 h-7 w-[78px] sm:h-[36px] sm:w-[54px] sm:leading-[36px]
                           xl:h-[46px] xl:w-[64px] xl:leading-[46px] rounded-3xl font-lexend-bold text-sm sm:text-base lg:text-[18px] font-bold ${
                               isChangeLanguage == 'ENG'
                                   ? ' bg-[#FF7559] opacity-100 text-white animate-fade-in'
                                   : ' opacity-100'
                           } transition-opacity duration-700 ease-out transform `}
                        >
                            EN
                        </p>
                        <p
                            onClick={handleChangeLanguageENG}
                            className={`z-10 m-[1px] xl:m-0  xl:px-[1px] leading-7 h-7 w-[78px] sm:h-[36px] sm:w-[54px] sm:leading-[36px]
                           xl:h-[46px] xl:w-[64px] xl:leading-[46px] rounded-3xl font-lexend-bold text-sm sm:text-base lg:text-[18px] font-bold ${
                               isChangeLanguage == 'VNG'
                                   ? ' bg-[#FF7559] opacity-100 text-white animate-fade-in'
                                   : ' opacity-100'
                           } transition-opacity duration-700 ease-out transform `}
                        >
                            VI
                        </p>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Navbar
