import React, { useEffect, useState } from 'react'
import Navbar from '../Component/Navbar'
import Footer from '../Component/Footer'
function Privacy(props) {
    const { isChangeLanguage } = props
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {isChangeLanguage == 'ENG' ? (
                <div className="font-lexend">
                    <div className="mt-[110px] mx-auto max-w-[1088px] h-full mb-[140px] px-2 sm:px-0">
                        <h2 className=" text-3xl sm:text-[52px] font-extrabold mb-[37px]">
                            Privacy Notice
                        </h2>
                        <p>
                            This Privacy Notice and its Addendum(s) (“Privacy Notice”) describe how Grab Holdings Inc,
                            its respective subsidiaries, affiliates, associated companies and jointly controlled
                            entities (collectively “<strong>Otto</strong>”, “we”, “us” or “our”) collect, use, process
                            and disclose your Personal Data through the use of Grab’s mobile applications and websites
                            (respectively “Apps” and “Websites”), as well as products, features and other services
                            globally, operated by Grab (collectively, “Services”)
                            <br />
                            This Privacy Notice applies to our consumers, agents, vendors, suppliers, partners (such as
                            driver, delivery and merchant partners), contractors and service providers (collectively
                            “you”, “your” or “yours”).
                            <br />
                            “Personal Data” is any information which can be used to identify you or from which you are
                            identifiable. This includes but is not limited to your name, nationality, telephone number,
                            bank and credit card details, personal interests, email address, your image,
                            government-issued identification numbers, biometric data, race, date of birth, marital
                            status, religion, health information, vehicle and insurance information, employment
                            information and financial information.
                            <br />
                            We typically collect, use, disclose or otherwise process your Personal Data in accordance
                            with this Privacy Notice with your consent, or in <br /> compliance with applicable laws,
                            such as where:
                        </p>
                        <ul className="list-disc ml-7">
                            <li>it is required for us to comply with legal requirements;</li>
                            <li>it is required for us to enter into or perform a contract with you; and/or</li>
                            <li>
                                for our legitimate interests or the legitimate interests of any other persons, including
                                but not limited to for the purposes set forth in this Privacy Notice;
                            </li>
                        </ul>
                        <div className="mt-5">
                            <h3 className="font-bold">I. COLLECTION OF PERSONAL DATA</h3>
                            <p>We collect (and may combine) Personal Data about you in the ways listed below:</p>
                            <h3 className="font-bold my-10">You provide your Personal Data to us</h3>
                            <p>We collect your Personal Data when you provide it to us. For example, when you:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    complete a user profile or registration forms (such as your name, contact
                                    information and other identification information where needed);
                                </li>
                                <li>
                                    provide information to assess your eligibility to provide services as a Otto driver
                                    partner or delivery partner (such as your driver’s license information, vehicle
                                    information and background check results);
                                </li>
                                <li>
                                    interact with our social media pages (such as your social media account ID, profile
                                    photo and any other publicly available data);
                                </li>
                                <li>
                                    participate in contests or events organised by us (such as the pictures, audio, or
                                    videos you may submit, which may include images of yourself);
                                </li>
                                <li>
                                    verify your identity through various means (such as social media logins, submission
                                    of selfie images or independently verified payment card information);
                                </li>
                                <li>
                                    fill up demographic information in surveys (such as your age, gender, and other
                                    information you may volunteer such as your marital status, occupation and income
                                    information); and
                                </li>
                                <li>agree to take a ride with in-vehicle audio and/or video recording features.</li>
                            </ul>
                            <h3 className="font-bold my-10">When our Services are used</h3>
                            <p>
                                Personal Data may be collected through the normal operation or use of our Apps, Websites
                                and Services. Some examples are:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>your location information;</li>
                                <li>feedback, ratings and compliments;</li>
                                <li>transaction information (such as payment method);</li>
                                <li>
                                    information about how you interacted with our Apps, Website or Services (such as
                                    features used and content viewed);
                                </li>
                                <li>
                                    device information (such as hardware model and serial number, IP addresses), file
                                    and app names and versions, GPS location, IMEI number, and advertising identifiers
                                    or any information that may indicate device or app modification);
                                </li>
                                <li>telematics data (such as your speed, acceleration and braking data);</li>
                                <li className="ml-7">
                                    we work with partners to install telematics devices in rental vehicles for the
                                    following purposes:
                                </li>
                                <li className="ml-14">
                                    to ensure that vehicles are maintained appropriately and serviced in a timely
                                    fashion;
                                </li>
                                <li className="ml-14">
                                    to help maintain the safety, security and integrity of our Services;
                                </li>
                                <li className="ml-14">to improve and enhance our Services; and</li>
                                <li className="ml-14">
                                    for internal tracking of the vehicle, analysis and administrative purposes.
                                </li>
                                <li className="ml-7">these partners own and share such telematics data with us:</li>
                                <li className="ml-14">
                                    our partners are also contractually obliged to safeguard this data.personal data you
                                    enter in messages when you use our in-app communication features; and
                                </li>
                                <li>
                                    personal data you enter in messages when you use our in-app communication features;
                                    and
                                </li>
                                <li>
                                    personal data that may be captured through your interaction with us, our agents,
                                    in-vehicle audio and/or video recording during a ride (such as your image or voice
                                    or both, and its related metadata).
                                </li>
                                <li className="ml-7">our in-vehicle applications or devices</li>
                                <li className="ml-14">
                                    we may install in-vehicle audio and/or video recording applications or devices to
                                    promote the safety and security of our driver partners, delivery partners and
                                    consumers.
                                </li>
                                <li className="ml-14">
                                    your Personal Data may be captured in these audio and/or video recordings. Where
                                    in-vehicle audio and/or video recordings are made, such recordings are collected,
                                    processed, used and stored in a manner in accordance with this Privacy Notice and
                                    applicable laws.
                                </li>
                            </ul>
                            <p>
                                Do note that some Grab partners may install personal in-vehicle applications or devices
                                in their vehicles for their own purposes, which may result in your Personal Data being
                                captured by them during the course of use of our Service. This practice is not endorsed
                                nor prohibited by Otto, and the collection, use and disclosure of Personal Data obtained
                                from personal in-vehicle applications or devices is the responsibility of the relevant
                                partner. Please check with the relevant partner if you have any quires about their use
                                of personal in-vehicle applications or devices.
                            </p>
                            <h3 className="font-bold my-10">From other sources</h3>
                            <p>
                                When we collect Personal Data, including but not limited to your name, contact
                                information and other identification information where needed from other sources. Such
                                sources include:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>referral programmes;</li>
                                <li>
                                    our business partners, such as fleet partners, payment providers, ride-hailing
                                    partners and transport partners;
                                </li>
                                <li>insurance and financial providers;</li>
                                <li>
                                    credit bureaus, alternative credit scoring agencies and any other credit reporting
                                    organisations;
                                </li>
                                <li>publicly available or governmental sources of data;</li>
                                <li>when our users add you as an emergency contact;</li>
                                <li>
                                    when our users add you as a recipient or beneficiary of any use of our Services;
                                </li>
                                <li>when you use our in-app chat; and</li>
                                <li>marketing services providers or partners.</li>
                            </ul>
                            <h3 className="font-bold my-10">Sensitive Personal Data</h3>
                            <p>
                                Some of the Personal Data that we collect may be sensitive in nature. This may include
                                Personal Data pertaining to your race, national ID information, religious beliefs,
                                background information (including financial and criminal records, where legally
                                permissible), health data, disability, marital status and biometric data, as applicable.
                                We collect sensitive Personal Data only with your consent and/or in strict compliance
                                with applicable laws. In the event that you are required to furnish any documentation or
                                information to us for any Purpose which may contain such sensitive Personal Data (which
                                is not required for that Purpose), you agree to redact such sensitive Personal Data
                                before providing such documentation or information to us.
                            </p>
                            <h3 className="font-bold my-10">
                                When you provide Personal Data of other individuals to us
                            </h3>
                            <p>
                                In some situations, you may provide Personal Data of other individuals (such as your
                                spouse, family members or friends) to us. For example, you may add them as your
                                emergency contact, when you use the in-app chat or when you add them as recipients or
                                beneficiaries of any use of our Services. If you provide us with their Personal Data,
                                you represent and warrant that you have obtained their consent for their Personal Data
                                to be collected, used and disclosed as set out in this Privacy Notice.
                            </p>
                            <h3 className="font-bold my-10">Personal Data of minors</h3>
                            <p>
                                As a parent or legal guardian, please do not allow minors under your care to submit
                                Personal Data to Otto. In the event that such Personal Data of a minor is disclosed to
                                Otto, you hereby consent to the processing of the minor’s Personal Data and accept and
                                agree to be bound by this Notice and take responsibility for his or her actions.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">II. USE OF PERSONAL DATA</h3>
                            <p>
                                Grab may use your Personal Data for the following purposes set out in the list below.
                                (“Purposes”). Additionally, if you use Otto in various capacities (for example, if you
                                use multiple Otto Services, or if you are both a driver partner/delivery partner and a
                                consumer), we may link your Personal Data collected across your various capacities to
                                facilitate your use of our Services and for the Purposes described below:
                            </p>
                            <h3 className="font-bold my-10">Providing services and features </h3>
                            <p>
                                Your Personal Data will be used to provide, personalise, maintain and improve our Apps,
                                Websites and Services. This includes using your Personal Data to:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>engage you to provide you with Services across our various business verticals;</li>
                                <li>create, administer and update your account;</li>
                                <li>
                                    process, manage or verify your eligibility for or application of Services,
                                    promotions, rewards and subscriptions with Grab;
                                </li>
                                <li>conduct due diligence checks and risk assessments / analysis;</li>
                                <li>verify your identity and age (where necessary);</li>
                                <li>validate your ride and process payments;</li>
                                <li>
                                    offer, obtain, provide, facilitate or maintain insurance or financing solutions;
                                </li>
                                <li>track the progress of your trip and detect abnormal trip variations;</li>
                                <li>
                                    personalise your Apps experience (such as to recommend products and services
                                    relevant to you, identify your preferences or otherwise personalise your experience
                                    with Otto);
                                </li>
                                <li>
                                    make your experience more seamless, such as automatically filling in your
                                    registration information (such as your name or phone number) from one Service to
                                    another Service or when you participate in our surveys;
                                </li>
                                <li>
                                    perform internal operations necessary to provide our Services, including
                                    troubleshooting software bugs and operational problems, conducting data analysis,
                                    testing and research, monitoring and analysing usage and activity trends;
                                </li>
                                <li>
                                    protect the security or integrity of the Services and any facilities or equipment
                                    used to make the Services available;
                                </li>
                                <li>enable communications between our users;</li>
                                <li>invite you to participate in our surveys and studies;</li>
                                <li>enable our partners to manage and allocate fleet resources; and</li>
                                <li>
                                    fulfil the services to you as a data processor, where you have provided consent to
                                    the data controller (i.e. the organisation you had purchased goods or services from,
                                    and for whom Otto is providing services on behalf of) for such services to be
                                    rendered.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Safety and security</h3>
                            <p>
                                We use your data to ensure the safety and security of our Services and all users. This
                                includes:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    screening driver and delivery partners before enabling their use of our Services;
                                </li>
                                <li>
                                    identifying unsafe driving behaviour such as speeding, harsh braking and
                                    acceleration, and providing personalised feedback to driver partners;
                                </li>
                                <li>verifying your identity when you log in to Otto;</li>
                                <li>
                                    using device, location, profile, usage and other Personal Data to prevent, detect
                                    and combat fraud or unsafe activities;
                                </li>
                                <li>
                                    sharing drivers and passengers of driver partners’ location and details when the
                                    emergency button or the “Share My Ride” feature is activated; and
                                </li>
                                <li>monitoring compliance with our terms and conditions and policies.</li>
                            </ul>
                            <h3 className="font-bold my-10">User support</h3>
                            <p>We use Personal Data to resolve user support issues. For example, we may:</p>
                            <ul className="list-disc ml-7">
                                <li>investigate and address concerns;</li>
                                <li>monitor and improve our user support responses;</li>
                                <li>respond to questions, comments and feedback; and</li>
                                <li>inform you about steps taken to resolve user support issues.</li>
                            </ul>
                            <h3 className="font-bold my-10">Research and development and security</h3>
                            <p>
                                We may use the Personal Data we collect for testing, research, analysis and product
                                development. This allows us to understand and analyse your needs and preferences,
                                protect your Personal Data, improve and enhance the safety and security of our Services,
                                develop new features, products and services, and facilitate development of insurance and
                                finance solutions.
                            </p>
                            <h3 className="font-bold my-10">Legal purposes</h3>
                            <p>
                                We may use the Personal Data we collect for legal purposes, such as to investigate and
                                resolve claims or disputes, detect/prevent/prosecute crime, or as allowed or required by
                                applicable law.
                                <br />
                                We may also use your Personal Data when we are required, advised, recommended, expected
                                or requested to do so by our legal advisors or any local or foreign legal, regulatory,
                                governmental or other authority.
                            </p>
                            <p>For example, we may use your Personal Data to:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    comply with court orders or other legal, governmental or regulatory requirements;
                                </li>
                                <li>enforce our Terms of Service or other agreements; and</li>
                                <li>protect our rights or property in the event of a claim or dispute.</li>
                            </ul>
                            <h3 className="font-bold my-10">Marketing and promotions</h3>
                            <p>
                                We may use your Personal Data to send you marketing communications relating to
                                customised products or services (that may be of interest or relevance based on your
                                profile), or Otto’s partners’, sponsors’ and advertisers’ products, services, events or
                                promotions. For example, we may:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    send you special offers that may be of interest or relevance to you across all of
                                    Otto’s service offerings;
                                </li>
                                <li>
                                    send you alerts, newsletters, updates, mailers, promotional materials, special
                                    privileges, festive greetings; and
                                </li>
                                <li>notify, invite and manage your participation in our events or activities.</li>
                            </ul>
                            <p>
                                We may communicate such marketing to you by various means, where applicable (including
                                by SMS, chat applications (e.g. WhatsApp, Telegram, LINE, Viber, WeChat, Zalo)), push
                                notification, call, and by email.
                                <br />
                                If you wish to unsubscribe to the receiving of such marketing communications, please
                                click on the unsubscribe link in the relevant email or message. Alternatively, you may
                                also update your preferences in our Apps settings. Please note that while you may opt
                                out of marketing or promotion communications, Grab may still send you service-related
                                messages (such as information on your transactions, rewards, etc.).
                            </p>
                            <h3 className="font-bold my-10">User Published Content</h3>
                            <p>
                                If you publish content (such as a review or comment) on Grab’s platform, it will be
                                shown publicly along with your personal data (such as profile name, uploaded photo(s)),
                                and other information you choose to include in your content. For more information, see
                                our Review Guidelines.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">III. DISCLOSURE OF PERSONAL DATA</h3>
                            <h3 className="my-10">
                                We need to share Personal Data with various parties for the Purposes. These parties
                                include:
                            </h3>
                            <p className="font-bold">Other users</p>
                            <p>example:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    If you are a passenger of driver partners, we may share your username, pick-up and
                                    drop-off locations with our driver partner fulfilling your Service request;
                                </li>
                                <li>
                                    If you are a driver partner, we may share your Personal Data with your passenger
                                    including your name and photo; your vehicle make, model, number plate, location and
                                    average rating;
                                </li>
                                <li>
                                    If you are a delivery partner, we may share your Personal Data with your selected
                                    merchant and user, including your name and photo; your vehicle make, model, location
                                    and average rating;
                                </li>
                                <li>
                                    If you are using our Otto Express Service, we may share your Personal Data with the
                                    recipient of your parcel, and vice versa, as well as the delivery partner in charge
                                    of fulfilling your service request; and
                                </li>
                                <li>
                                    If you use our in-app chat, we may share your mobile number and Otto-registered name
                                    with the other parties to your chat.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">With third parties</h3>
                            <h3 className="font-bold my-10">With third parties related to your use of Otto Services</h3>
                            <p>example:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    If you are a passenger of driver partners, we may share a vehicle’s location and
                                    driver’s and/or your name with third parties when you use the “Share My Ride”
                                    feature or activate the Emergency Button.
                                </li>
                                <li>
                                    If you are a driver-partner, we may disclose your vehicle plate number or other
                                    personal data to the insurance company and/or to your passenger for the purpose of
                                    submission of insurance claims..
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">With the owner of Grab accounts that you may use</h3>
                            <p>
                                For example, your employer may receive trip data (tagged as business rides) when you use
                                your employer’s Otto for Business account.
                            </p>
                            <h3 className="font-bold my-10">With subsidiaries and affiliates</h3>
                            <p>
                                We share Personal Data with our subsidiaries, associated companies, jointly controlled
                                entities and affiliates. The companies in the Otto group share resources, such as
                                infrastructure and technology, with each other to provide you with a consistent,
                                relevant and secure experience when you use our Services, and for the Purposes set out
                                in this Privacy Notice. For example, we do this to make your experience more seamless,
                                such as by automatically completing your registration information from your use of one
                                Otto’s Service when you sign up for a new Otto Service.
                            </p>
                            <h3 className="font-bold my-10">With Otto’s service providers and business partners</h3>
                            <p className=" my-10">
                                We may provide Personal Data to our vendors, consultants, marketing partners, research
                                firms, and other service providers or business partners.
                            </p>
                            <p>This includes:</p>
                            <ul className="list-disc ml-7">
                                <li>payment processors and facilitators;</li>
                                <li>debt collectors;</li>
                                <li>
                                    credit bureaus, alternative credit scoring agencies and any other credit reporting
                                    organisations;
                                </li>
                                <li>background check and anti-money laundering service providers;</li>
                                <li>cloud storage providers;</li>
                                <li>advertising and marketing partners and platform providers;</li>
                                <li>data analytics providers;</li>
                                <li>
                                    research partners, including those performing surveys or research projects in
                                    partnership with Otto or on Otto’s behalf;
                                </li>
                                <li>educational and training institute partners;</li>
                                <li>fleet and merchant partners;</li>
                                <li>insurance and financing partners;</li>
                                <li>
                                    third party intermediaries involved in the managed investment of funds, such as
                                    brokers, asset managers, and custodians;
                                </li>
                                <li>service providers who perform identity verification services; and</li>
                                <li>vehicle solutions partners, vendors or third-party vehicle suppliers.</li>
                            </ul>
                            <p className="mt-4">For example:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    If you requested a service through a Otto partner or used a promotion provided by a
                                    Otto partner, Otto may share your Personal Data with that Otto partner.
                                </li>
                                <li>
                                    If you have rented a vehicle from our recommended fleet partners to drive with Otto,
                                    we may share relevant information (e.g. the status of your account, applicable
                                    incentives/promotions, your ride statistics) for collaborative efforts (e.g. create
                                    new incentives and benefits for you or improving supply of driver partners).
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">With our legal advisors and governmental authorities</h3>
                            <p>
                                We may share your Personal Data with our legal advisors, law enforcement officials,
                                government authorities and other third parties. This may take place to fulfil the legal
                                purposes (mentioned above), or any of the following circumstances:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    where it is necessary to respond to an emergency that threatens the life, health or
                                    safety of a person; or
                                </li>
                                <li>
                                    where it is necessary in the public interest (e.g. in a public health crisis, for
                                    contact tracing purposes and safeguarding our community).
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Business transfers</h3>
                            <p>
                                We may share your Personal Data with other parties, in connection with any acquisitions,
                                sales, mergers, joint ventures, consolidation, restructuring, financing or any other
                                type of business transactions. Your Personal Data will however remain subject to our
                                obligations made in any pre-existing Privacy Notice that you have agreed to.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">IV. RETENTION OF PERSONAL DATA</h3>
                            <p className="my-10">
                                We retain your Personal Data for the period necessary to fulfill the Purposes outlined
                                in this Privacy Notice unless a longer retention period is required or allowed by law.
                                Once your Personal Data is no longer necessary for the Services or Purposes, or we no
                                longer have a legal or business purpose for retaining your Personal Data, we take steps
                                to erase, destroy, anonymise or prevent access or use of such Personal Data for any
                                purpose other than compliance with this Privacy Notice, or for purposes of safety,
                                security, fraud prevention and detection, in accordance with the requirements of
                                applicable laws.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">V. INTERNATIONAL TRANSFERS OF PERSONAL DATA</h3>
                            <p className="mt-4">
                                Your Personal Data may be transferred from country, state and city (“Home Country”) in
                                which you are present while using our Services to another country, state and city
                                (“Alternate Country”).
                                <br />
                                When we transfer your Personal Data from your Home Country to the Alternate Country, we
                                will comply with our legal and regulatory obligations in relation to your Personal Data,
                                including having a lawful basis for transferring Personal Data and putting appropriate
                                safeguards in place to ensure an adequate level of protection for the Personal Data. We
                                will also ensure that the recipient in Alternate Country is obliged to protect your
                                Personal Data at a standard of protection comparable to the protection under applicable
                                laws.
                                <br />
                                Our lawful basis will be either consent (i.e. we may ask for your consent to transfer
                                your Personal Data from your Home Country to the Alternate Country at the time you
                                provide your Personal Data) or one of the safeguards permissible by laws.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold ">VI. COOKIES AND ADVERTISING ON THIRD PARTY PLATFORMS</h3>
                            <h3 className="font-bold my-10 ">Cookies</h3>
                            <p>
                                Otto, and third parties with whom we partner, may use cookies, web beacons, tags,
                                scripts, local shared objects such as HTML5, advertising identifiers and similar
                                technologies (collectively, “Cookies”) in connection with your use of the Websites and
                                Apps, which may be persistent or stored only during an individual session on your
                                browsers or devices.
                                <br />
                                Cookies may have unique identifiers, and reside, among other places, on your computer or
                                mobile device, in emails we send to you, and on our web pages. Cookies may be used for
                                various purposes such as to:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    provide the essential, basic functions of the use of our Websites, Services or Apps;
                                </li>
                                <li>authenticate you, or remember your user preferences and settings;</li>
                                <li>
                                    delivering and measuring the effectiveness of advertising campaigns, such as by
                                    measuring number of views or clickthroughs;
                                </li>
                                <li>analyse site traffic and trends;</li>
                                <li>
                                    serve you relevant advertisements across other apps and websites owned by other
                                    companies (whether by us or by other advertisers); and
                                </li>
                                <li>
                                    enhance user interface and experience by generally understanding the online
                                    behaviors and interests of users who interact with our Website, Services or Apps.
                                </li>
                            </ul>
                            <p>
                                Otto may allow third parties to use Cookies on the Websites and Apps to collect the same
                                type of Personal Data for the same purposes Otto does for itself. Third parties may be
                                able to associate the Personal Data they collect with other Personal Data they have
                                about you from other sources. We do not necessarily have access to or control over the
                                Cookies they use.
                            </p>
                            <h3 className="font-bold my-10 ">Disabling the collection of cookies on our websites</h3>
                            <p>
                                You have the right to choose to disable, block or deactivate cookies. Please note that
                                however refusal or removal of some cookies could affect the availability, functionality
                                or use of our Website, Services or Apps.
                                <br />
                                You may adjust your internet browser settings to disable, block or deactivate cookies,
                                delete your browsing history or clear the cache from your internet browser. The settings
                                may be contained within the “History”, “Preferences”, “Settings” or “Privacy” section of
                                your internet browsers, or you may otherwise consult the help section of your internet
                                browser for more information.
                            </p>
                            <h3 className="font-bold my-10 ">Disabling the display of Targeted Ads through our App</h3>
                            <p>
                                We may facilitate the display of targeted advertising on other platforms, such as on
                                other online platforms that you may be a user of.
                                <br />
                                You may be able to limit our use of your Personal Data to display targeted
                                advertisements on behalf of our advertisers, by adjusting your preference through the
                                Apps. Depending on the type of phone and operating software that you are using, you may
                                be granted the option to disable our use of mobile identifiers (such as Apple’s IDFA or
                                Google’s GAID) and the ability to track you across apps and websites owned by other
                                companies by adjusting the settings available on your mobile devices. Through adjusting
                                the settings on your device, you can typically reset, delete, limit and/or otherwise
                                restrict the use of such mobile identifiers.
                                <br />
                                Although we will not use your Personal Data to show you such targeted advertisements on
                                other platforms, you may still see generic ads that appear relevant to you. This may be
                                because we have engaged third party advertisers / platforms to display ads to users of
                                specific attributes or demographic makeups who may find them relevant. You may be able
                                to limit seeing these advertisements through managing your preferences on these
                                platforms.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">VII. Protection of Personal Data</h3>
                            <p className="">
                                We will take reasonable legal, organisational and technical measures to ensure that your
                                Personal Data is protected. This includes measures to prevent Personal Data from getting
                                lost, or used or accessed in an unauthorised way. We limit access to your Personal Data
                                to our employees on a need to know basis. Those processing your Personal Data will only
                                do so in an authorised manner and are required to treat your information with
                                confidentiality.
                                <br />
                                Nevertheless, please understand that the transmission of information via the internet is
                                not completely secure. Although we will do our best to protect your Personal Data, we
                                cannot guarantee the security of your Personal Data transmitted through any online
                                means, therefore, any transmission remains at your own risk.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">VIII. YOUR RIGHTS WITH RESPECT TO YOUR PERSONAL DATA</h3>
                            <p className="">
                                In accordance with applicable laws and regulations, you may be entitled to:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    inquire about the processing of your Personal Data and be provided with a copy of
                                    it;
                                </li>
                                <li>in some cases, request the correction and/or deletion of your Personal Data;</li>
                                <li>
                                    in some cases, request the restriction of or object to the processing of your
                                    Personal Data;
                                </li>
                                <li>
                                    withdraw your consent to the processing of your Personal Data (where we are
                                    processing your Personal Data based on your consent);
                                </li>
                                <li>
                                    request receipt or transmission to another organisation, in a machine-readable form,
                                    of the Personal Data that you have provided to us where we are using your Personal
                                    Data based on consent or performance of a contract; and
                                </li>
                                <li>
                                    complain to the relevant data privacy authority if your data privacy rights are
                                    violated, or if you have suffered detriment as a result of unlawful processing of
                                    your Personal Data.
                                </li>
                            </ul>
                            <p>
                                Where you are given the option to share your Personal Data with us, you can always
                                choose not to do so. If we have requested your consent to processing and you later
                                choose to withdraw it, we will respect that choice in accordance with our legal
                                obligations.
                                <br />
                                However, choosing not to share your Personal Data with us, withdrawing your consent to
                                our use of it or to your agreement with our Privacy Notice could mean that we are unable
                                to perform the actions necessary to achieve the purposes of processing described in
                                Section II (Use of Personal Data) or that you are unable to make use of the Apps or the
                                Services.
                                <br />
                                fter you have chosen to withdraw your consent, we may be able to continue to process
                                your Personal Data to the extent required or otherwise permitted by applicable laws and
                                regulations.
                                <br />
                                We will screen and verify all requests beforehand. In order to verify your identity
                                and/or authority to make the request, we may require you to provide supporting
                                information or documentation to corroborate the request. Once verified, we will give
                                effect to your request within the timelines prescribed by applicable laws.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">IX. AMENDMENTS AND UPDATES</h3>
                            <p className="">
                                Otto may modify, update or amend the terms in this Privacy Notice at any time. Such
                                amendments shall be notified to you through the Apps and/ or other appropriate means at
                                least five (5) business days before the effective date. The updated version will be
                                posted on the website
                                <strong>http://www.Otto.com</strong>. It is your responsibility to review the Privacy
                                Notice regularly. Your continued use of the Apps, Websites or Services, or continuing to
                                communicate or engage with Grab following the effective date of the modifications,
                                updates or amendments to this Privacy Notice , whether or not reviewed by you, shall
                                constitute your agreement to be bound by such amendments.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">X. HOW TO CONTACT US</h3>
                            <p className="">
                                If you have any queries about this Notice or would like to exercise your rights set out
                                in this Notice , please fill-in the following form or direct your mail to our Data
                                Protection Officer :
                            </p>
                            <ul className="list-disc ml-7">
                                <li>...</li>
                                <li>...</li>
                            </ul>
                        </div>
                    </div>
                  
                </div>
            ) : (
                <div>
                    <div className="mt-[110px] mx-auto max-w-[1088px] h-full mb-[140px] px-2 sm:px-0">
                        <h2 className=" text-3xl sm:text-[52px] font-extrabold mb-[37px]">
                        Thông báo Bảo mật
                        </h2>
                        <p>
                            Thông báo Quyền riêng tư này cùng với các Phụ lục của nó (“Thông báo Quyền riêng tư”) mô tả
                            cách Grab Holdings Inc, các chi nhánh, liên kết, công ty liên kết và các đơn vị kiểm soát
                            chung (gọi chung là “<strong>Otto</strong>”, “chúng tôi”, “chúng ta” hoặc “của chúng tôi”)
                            thu thập, sử dụng, xử lý và tiết lộ Dữ liệu Cá nhân của bạn thông qua việc sử dụng ứng dụng
                            di động và trang web của Grab (tương ứng là “Ứng dụng” và “Trang web”), cũng như sản phẩm,
                            tính năng và các dịch vụ khác trên toàn cầu, do Grab vận hành (gọi chung là “Dịch vụ”).
                            <br />
                            Thông báo Quyền riêng tư này áp dụng đối với người tiêu dùng, đại lý, nhà cung cấp, đối tác
                            (như lái xe, đối tác giao hàng và đối tác thương gia), nhà thầu và nhà cung cấp dịch vụ (gọi
                            chung là “bạn”, “bạn” hoặc “của bạn”).
                            <br />
                            “Dữ liệu Cá nhân” là bất kỳ thông tin nào có thể được sử dụng để xác định bạn hoặc từ đó bạn
                            có thể được xác định. Điều này bao gồm nhưng không giới hạn đến tên của bạn, quốc tịch, số
                            điện thoại, chi tiết ngân hàng và thẻ tín dụng, sở thích cá nhân, địa chỉ email, hình ảnh
                            của bạn, số căn cước công dân, dữ liệu sinh học, sắc tộc, ngày sinh, tình trạng hôn nhân,
                            tôn giáo, thông tin sức khỏe, thông tin về phương tiện và bảo hiểm, thông tin về việc làm và
                            thông tin tài chính.
                            <br />
                            Chúng tôi thường thu thập, sử dụng, tiết lộ hoặc xử lý Dữ liệu Cá nhân của bạn theo Thông
                            báo Quyền riêng tư này dựa trên sự đồng ý của bạn, hoặc theo <br /> tuân thủ các luật lệ
                            hiện hành, như trong trường hợp:
                        </p>
                        <ul>
                            <li>Đó là yêu cầu để chúng tôi tuân thủ các yêu cầu pháp lý;</li>
                            <li>Đó là yêu cầu để chúng tôi ký kết hoặc thực hiện một hợp đồng với bạn; và/hoặc</li>
                            <li>
                                Cho các lợi ích hợp pháp của chúng tôi hoặc lợi ích hợp pháp của bất kỳ người nào khác,
                                bao gồm nhưng không giới hạn đến các mục đích được mô tả trong Thông báo Quyền riêng tư
                                này;
                            </li>
                        </ul>

                        <div className="mt-5">
                            <h3 className="font-bold">I. VIỆC THU THẬP DỮ LIỆU CÁ NHÂN</h3>
                            <p>Chúng tôi thu thập (và có thể kết hợp) Dữ liệu Cá nhân về bạn theo các cách sau:</p>
                            <h3 className="font-bold my-10">Bạn cung cấp Dữ liệu Cá nhân cho chúng tôi</h3>
                            <p>
                                Chúng tôi thu thập Dữ liệu Cá nhân của bạn khi bạn cung cấp nó cho chúng tôi. Ví dụ, khi
                                bạn:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    hoàn thành hồ sơ người dùng hoặc các biểu mẫu đăng ký (như tên, thông tin liên hệ và
                                    các thông tin nhận diện khác khi cần thiết);
                                </li>
                                <li>
                                    cung cấp thông tin để đánh giá khả năng của bạn để cung cấp dịch vụ như một đối tác
                                    lái xe hoặc đối tác giao hàng cho Otto (như thông tin về giấy phép lái xe, thông tin
                                    về phương tiện và kết quả kiểm tra lịch sử);
                                </li>
                                <li>
                                    tương tác với trang truyền thông xã hội của chúng tôi (như ID tài khoản truyền thông
                                    xã hội, ảnh hồ sơ và bất kỳ dữ liệu nào khác có sẵn công khai);
                                </li>
                                <li>
                                    tham gia cuộc thi hoặc sự kiện do chúng tôi tổ chức (như hình ảnh, âm thanh hoặc
                                    video bạn có thể gửi, bao gồm hình ảnh của chính bạn);
                                </li>
                                <li>
                                    xác minh danh tính của bạn qua các phương tiện khác nhau (như đăng nhập truyền thông
                                    xã hội, gửi hình ảnh tự sướng hoặc thông tin thẻ thanh toán được xác minh độc lập);
                                </li>
                                <li>
                                    điền thông tin dân số trong các cuộc khảo sát (như tuổi, giới tính và các thông tin
                                    bạn có thể tự nguyện như tình trạng hôn nhân, nghề nghiệp và thông tin thu nhập); và
                                </li>
                                <li>đồng ý tham gia chuyến đi với tính năng ghi âm và/hoặc ghi video trong xe.</li>
                            </ul>
                            <h3 className="font-bold my-10">Khi Dịch vụ của chúng tôi được sử dụng</h3>
                            <p>
                                Dữ liệu Cá nhân có thể được thu thập thông qua hoạt động hoặc việc sử dụng bình thường
                                của Ứng dụng, Trang web và Dịch vụ của chúng tôi. Một số ví dụ như sau:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>thông tin vị trí của bạn;</li>
                                <li>phản hồi, đánh giá và lời khen;</li>
                                <li>thông tin giao dịch (như phương thức thanh toán);</li>
                                <li>
                                    thông tin về cách bạn tương tác với Ứng dụng, Trang web hoặc Dịch vụ của chúng tôi
                                    (như tính năng được sử dụng và nội dung được xem);
                                </li>
                                <li>
                                    thông tin thiết bị (như mô hình và số sê-ri của phần cứng, địa chỉ IP), tên và phiên
                                    bản file và ứng dụng, vị trí GPS, số IMEI và các định danh quảng cáo hoặc bất kỳ
                                    thông tin nào có thể chỉ ra sự thay đổi về thiết bị hoặc ứng dụng);
                                </li>
                                <li>dữ liệu telematics (như tốc độ, dữ liệu gia tốc và phanh của bạn);</li>
                                <li className="ml-7">
                                    chúng tôi hợp tác với đối tác để lắp đặt thiết bị telematics trong phương tiện thuê
                                    để đảm bảo rằng các phương tiện được bảo dưỡng đúng cách và được bảo dưỡng đúng thời
                                    hạn;
                                </li>
                                <li className="ml-14">
                                    để giúp duy trì an toàn, bảo mật và tính toàn vẹn của Dịch vụ của chúng tôi;
                                </li>
                                <li className="ml-14">để cải thiện và nâng cao Dịch vụ của chúng tôi; và</li>
                                <li className="ml-14">
                                    cho việc theo dõi nội bộ của phương tiện, mục đích phân tích và mục đích quản trị.
                                </li>
                                <li className="ml-7">
                                    các đối tác này sở hữu và chia sẻ dữ liệu telematics này với chúng tôi:
                                </li>
                                <li className="ml-14">
                                    đối tác của chúng tôi cũng có nghĩa vụ theo hợp đồng để bảo vệ dữ liệu này. Dữ liệu
                                    cá nhân mà bạn nhập trong các tin nhắn khi bạn sử dụng tính năng trò chuyện trong
                                    ứng dụng của chúng tôi; và
                                </li>
                                <li>
                                    dữ liệu cá nhân mà bạn nhập trong các tin nhắn khi bạn sử dụng tính năng trò chuyện
                                    trong ứng dụng của chúng tôi; và
                                </li>
                                <li>
                                    dữ liệu cá nhân có thể được thu thập thông qua sự tương tác của bạn với chúng tôi,
                                    đại lý của chúng tôi, ghi âm và/hoặc quay video trong xe trong suốt chuyến đi (như
                                    hình ảnh hoặc giọng của bạn hoặc cả hai và các siêu dữ liệu liên quan).
                                </li>
                                <li className="ml-7">ứng dụng hoặc thiết bị trong xe của chúng tôi</li>
                                <li className="ml-14">
                                    chúng tôi có thể cài đặt các ứng dụng hoặc thiết bị ghi âm và/hoặc quay video trong
                                    xe để tăng cường an toàn và bảo mật cho đối tác lái xe, đối tác giao hàng và người
                                    tiêu dùng của chúng tôi.
                                </li>
                                <li className="ml-14">
                                    Dữ liệu Cá nhân của bạn có thể được thu thập trong các bản ghi âm và/hoặc video
                                    trong xe này. Khi có bản ghi âm và/hoặc video trong xe, các bản ghi đó được thu
                                    thập, xử lý, sử dụng và lưu trữ theo cách tuân thủ Thông báo Quyền riêng tư này và
                                    theo luật pháp áp dụng.
                                </li>
                            </ul>
                            <p>
                                Lưu ý rằng một số đối tác Grab có thể cài đặt các ứng dụng hoặc thiết bị cá nhân trong
                                xe của họ cho mục đích riêng của họ, điều này có thể dẫn đến việc Dữ liệu Cá nhân của
                                bạn được thu thập bởi họ trong quá trình sử dụng Dịch vụ của chúng tôi. Thực tế này
                                không được Otto bảo lưu cũng như không bị cấm, và việc thu thập, sử dụng và tiết lộ Dữ
                                liệu Cá nhân được thu thập từ các ứng dụng hoặc thiết bị cá nhân trong xe là trách nhiệm
                                của đối tác liên quan. Vui lòng kiểm tra với đối tác liên quan nếu bạn có bất kỳ câu hỏi
                                nào về việc họ sử dụng các ứng dụng hoặc thiết bị cá nhân trong xe.
                            </p>
                            <h3 className="font-bold my-10">Từ các nguồn khác nhau</h3>
                            <p>
                                Khi chúng tôi thu thập Dữ liệu Cá nhân, bao gồm nhưng không giới hạn đến tên của bạn,
                                thông tin liên lạc và thông tin nhận dạng khác khi cần thiết từ các nguồn khác. Các
                                nguồn này bao gồm:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>chương trình giới thiệu;</li>
                                <li>
                                    đối tác kinh doanh của chúng tôi, như các đối tác đội xe, nhà cung cấp thanh toán,
                                    đối tác chuyến đi và đối tác vận chuyển;
                                </li>
                                <li>nhà cung cấp bảo hiểm và tài chính;</li>
                                <li>
                                    cơ quan thông tin tín dụng, các cơ quan đánh giá tín dụng thay thế và bất kỳ tổ chức
                                    báo cáo tín dụng nào khác;
                                </li>
                                <li>dữ liệu từ nguồn công cộng hoặc các nguồn dữ liệu chính phủ;</li>
                                <li>khi người dùng của chúng tôi thêm bạn làm liên lạc khẩn cấp;</li>
                                <li>
                                    khi người dùng của chúng tôi thêm bạn làm người nhận hoặc người hưởng lợi của bất kỳ
                                    sử dụng Dịch vụ nào của chúng tôi;
                                </li>
                                <li>khi bạn sử dụng tính năng trò chuyện trong ứng dụng của chúng tôi; và</li>
                                <li>nhà cung cấp hoặc đối tác dịch vụ tiếp thị.</li>
                            </ul>
                            <h3 className="font-bold my-10">Dữ liệu Cá nhân Nhạy cảm</h3>
                            <p>
                                Một số Dữ liệu Cá nhân mà chúng tôi thu thập có thể có tính nhạy cảm. Điều này có thể
                                bao gồm Dữ liệu Cá nhân liên quan đến chủng tộc của bạn, thông tin chứng minh nhân dân,
                                tín ngưỡng tôn giáo, thông tin nền (bao gồm cả hồ sơ tài chính và tư pháp, nếu pháp lý
                                cho phép), dữ liệu về sức khỏe, khuyết tật, tình trạng hôn nhân và dữ liệu sinh trắc
                                học, nếu áp dụng. Chúng tôi chỉ thu thập Dữ liệu Cá nhân nhạy cảm khi có sự đồng ý của
                                bạn và/hoặc tuân thủ chặt chẽ các luật pháp liên quan. Trong trường hợp bạn cần cung cấp
                                bất kỳ tài liệu hoặc thông tin nào cho chúng tôi cho bất kỳ Mục đích nào có thể chứa Dữ
                                liệu Cá nhân nhạy cảm như vậy (mà không cần thiết cho Mục đích đó), bạn đồng ý che giấu
                                Dữ liệu Cá nhân nhạy cảm trước khi cung cấp tài liệu hoặc thông tin đó cho chúng tôi.
                            </p>
                            <h3 className="font-bold my-10">
                                Khi bạn cung cấp Dữ liệu Cá nhân của người khác cho chúng tôi
                            </h3>
                            <p>
                                Trong một số tình huống, bạn có thể cung cấp Dữ liệu Cá nhân của người khác (như chồng,
                                thành viên trong gia đình hoặc bạn bè) cho chúng tôi. Ví dụ, bạn có thể thêm họ làm liên
                                lạc khẩn cấp, khi bạn sử dụng trò chuyện trong ứng dụng hoặc khi bạn thêm họ làm người
                                nhận hoặc người hưởng lợi của bất kỳ sử dụng Dịch vụ nào của chúng tôi. Nếu bạn cung cấp
                                chúng tôi Dữ liệu Cá nhân của họ, bạn tuyên bố và đảm bảo rằng bạn đã nhận được sự đồng
                                ý của họ để Dữ liệu Cá nhân của họ được thu thập, sử dụng và tiết lộ theo như mô tả
                                trong Thông báo Quyền riêng tư này.
                            </p>
                            <h3 className="font-bold my-10">Dữ liệu Cá nhân của trẻ em</h3>
                            <p>
                                Làm cha mẹ hoặc người giám hộ pháp lý, vui lòng không cho phép trẻ em dưới sự chăm sóc
                                của bạn gửi Dữ liệu Cá nhân cho Otto. Trong trường hợp Dữ liệu Cá nhân của trẻ em được
                                tiết lộ cho Otto, bạn đồng ý với việc xử lý Dữ liệu Cá nhân của trẻ và chấp nhận và đồng
                                ý tuân theo Thông báo này và chịu trách nhiệm cho hành động của trẻ.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">II. SỬ DỤNG DỮ LIỆU CÁ NHÂN</h3>
                            <p>
                                Grab có thể sử dụng Dữ liệu Cá nhân của bạn cho các mục đích được liệt kê dưới đây (“Mục
                                Đích”). Ngoài ra, nếu bạn sử dụng Otto ở nhiều vai trò khác nhau (ví dụ, nếu bạn sử dụng
                                nhiều Dịch vụ Otto hoặc nếu bạn đồng thời là đối tác lái xe/đối tác giao hàng và một
                                người tiêu dùng), chúng tôi có thể liên kết Dữ liệu Cá nhân của bạn được thu thập qua
                                các vai trò khác nhau để hỗ trợ việc sử dụng Dịch vụ của chúng tôi và cho các Mục Đích
                                mô tả dưới đây:
                            </p>
                            <h3 className="font-bold my-10">Cung cấp dịch vụ và tính năng</h3>
                            <p>
                                Dữ liệu Cá nhân của bạn sẽ được sử dụng để cung cấp, cá nhân hóa, duy trì và cải thiện
                                Ứng dụng, Trang web và Dịch vụ của chúng tôi. Điều này bao gồm việc sử dụng Dữ liệu Cá
                                nhân của bạn để:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    liên kết với bạn để cung cấp Dịch vụ cho bạn qua nhiều lĩnh vực kinh doanh của chúng
                                    tôi;
                                </li>
                                <li>tạo, quản trị và cập nhật tài khoản của bạn;</li>
                                <li>
                                    xử lý, quản lý hoặc xác nhận độ chất lượng của bạn hoặc đăng ký Dịch vụ, khuyến mãi,
                                    phần thưởng và đăng ký với Grab;
                                </li>
                                <li>tiến hành kiểm tra hậu kiểm và đánh giá/phan tích rủi ro;</li>
                                <li>xác nhận danh tính và tuổi của bạn (khi cần thiết);</li>
                                <li>kiểm tra chuyến đi của bạn và xử lý thanh toán;</li>
                                <li>
                                    cung cấp, thu thập, duy trì, hỗ trợ hoặc duy trì giải pháp bảo hiểm hoặc tài chính;
                                </li>
                                <li>theo dõi tiến trình của chuyến đi và phát hiện biến thường không bình thường;</li>
                                <li>
                                    cá nhân hóa trải nghiệm Ứng dụng của bạn (ví dụ: để đề xuất sản phẩm và dịch vụ phù
                                    hợp với bạn, xác định sở thích của bạn hoặc cá nhân hóa trải nghiệm của bạn với
                                    Otto);
                                </li>
                                <li>
                                    làm cho trải nghiệm của bạn trở nên mượt mà hơn, chẳng hạn như tự động điền thông
                                    tin đăng ký của bạn (như tên hoặc số điện thoại) từ một Dịch vụ này sang Dịch vụ
                                    khác hoặc khi bạn tham gia vào các cuộc khảo sát của chúng tôi;
                                </li>
                                <li>
                                    thực hiện các hoạt động nội bộ cần thiết để cung cấp Dịch vụ của chúng tôi, bao gồm
                                    sửa lỗi phần mềm và vấn đề hoạt động, tiến hành phân tích dữ liệu, kiểm tra và
                                    nghiên cứu, theo dõi và phân tích xu hướng sử dụng và hoạt động;
                                </li>
                                <li>
                                    bảo vệ an ninh hoặc tính nguyên vẹn của Dịch vụ và bất kỳ cơ sở vật chất hoặc thiết
                                    bị nào được sử dụng để cung cấp Dịch vụ;
                                </li>
                                <li>kích thích giao tiếp giữa người dùng của chúng tôi;</li>
                                <li>mời bạn tham gia vào các cuộc khảo sát và nghiên cứu của chúng tôi;</li>
                                <li>cho phép đối tác của chúng tôi quản lý và phân phối nguồn lực đội xe; và</li>
                                <li>
                                    thực hiện các dịch vụ đối với bạn như một bộ xử lý dữ liệu, trong trường hợp bạn đã
                                    đồng ý cho bộ điều khiển dữ liệu (tức là tổ chức mà bạn đã mua hàng hoặc dịch vụ từ
                                    đó, và mà Otto đang cung cấp dịch vụ cho tổ chức đó) để thực hiện các dịch vụ đó.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">An toàn và bảo mật</h3>
                            <p>
                                Chúng tôi sử dụng dữ liệu của bạn để đảm bảo an toàn và bảo mật của Dịch vụ và tất cả
                                người dùng. Điều này bao gồm:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    kiểm tra đối tác lái xe và giao hàng trước khi cho phép họ sử dụng Dịch vụ của chúng
                                    tôi;
                                </li>
                                <li>
                                    xác định hành vi lái xe không an toàn như lái xe quá tốc độ, phanh gấp và tăng tốc
                                    mạnh, và cung cấp phản hồi cá nhân hóa cho đối tác lái xe;
                                </li>
                                <li>xác nhận danh tính của bạn khi bạn đăng nhập vào Otto;</li>
                                <li>
                                    sử dụng dữ liệu từ thiết bị, vị trí, hồ sơ, sử dụng và các Dữ liệu Cá nhân khác để
                                    ngăn chặn, phát hiện và chiến đấu chống lại gian lận hoặc các hoạt động không an
                                    toàn;
                                </li>
                                <li>
                                    chia sẻ vị trí và chi tiết của lái xe và hành khách của đối tác lái xe khi nút khẩn
                                    cấp hoặc tính năng “Chia sẻ Chuyến đi của Tôi” được kích hoạt; và
                                </li>
                                <li>
                                    theo dõi việc tuân thủ các điều khoản và điều kiện cũng như chính sách của chúng
                                    tôi.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Hỗ trợ người dùng</h3>
                            <p>
                                Chúng tôi sử dụng Dữ liệu Cá nhân để giải quyết các vấn đề hỗ trợ người dùng. Ví dụ,
                                chúng tôi có thể:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>điều tra và giải quyết các vấn đề đang gặp;</li>
                                <li>theo dõi và cải thiện phản hồi hỗ trợ người dùng của chúng tôi;</li>
                                <li>trả lời câu hỏi, ý kiến và phản hồi; và</li>
                                <li>
                                    thông báo cho bạn về các bước đã được thực hiện để giải quyết các vấn đề hỗ trợ
                                    người dùng.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Nghiên cứu và phát triển và bảo mật</h3>
                            <p>
                                Chúng tôi có thể sử dụng Dữ liệu Cá nhân chúng tôi thu thập được cho việc kiểm tra,
                                nghiên cứu, phân tích và phát triển sản phẩm. Điều này giúp chúng tôi hiểu và phân tích
                                nhu cầu và sở thích của bạn, bảo vệ Dữ liệu Cá nhân của bạn, cải thiện và tăng cường an
                                toàn và bảo mật của Dịch vụ của chúng tôi, phát triển tính năng, sản phẩm và dịch vụ
                                mới, và hỗ trợ việc phát triển các giải pháp bảo hiểm và tài chính.
                            </p>
                            <h3 className="font-bold my-10">Mục đích pháp lý</h3>
                            <p>
                                Chúng tôi có thể sử dụng Dữ liệu Cá nhân mà chúng tôi thu thập được cho các mục đích
                                pháp lý, chẳng hạn như điều tra và giải quyết các khiếu nại hoặc tranh chấp, phát
                                hiện/phòng ngừa/truy cứu trách nhiệm hình sự, hoặc theo như cho phép hoặc yêu cầu bởi
                                luật pháp hiện hành.
                                <br />
                                Chúng tôi cũng có thể sử dụng Dữ liệu Cá nhân của bạn khi chúng tôi được yêu cầu, khuyến
                                nghị, mong đợi hoặc được yêu cầu làm như vậy bởi các cố vấn pháp lý của chúng tôi hoặc
                                bất kỳ cơ quan pháp lý, quy định, chính phủ hoặc cơ quan nào khác ở địa phương hoặc quốc
                                tế.
                            </p>
                            <p>Ví dụ, chúng tôi có thể sử dụng Dữ liệu Cá nhân của bạn để:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    tuân thủ các lệnh của tòa án hoặc các yêu cầu pháp lý, chính phủ hoặc cơ quan quy
                                    định khác;
                                </li>
                                <li>thực thi Điều khoản Dịch vụ hoặc các hợp đồng khác; và</li>
                                <li>
                                    bảo vệ quyền lợi hoặc tài sản của chúng tôi trong trường hợp có khiếu nại hoặc tranh
                                    chấp.
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold my-10">Marketing và Khuyến mãi</h3>
                            <p>
                                Chúng tôi có thể sử dụng Dữ liệu Cá nhân của bạn để gửi thông tin tiếp thị liên quan đến
                                các sản phẩm hoặc dịch vụ được tùy chỉnh (có thể phù hợp hoặc liên quan dựa trên hồ sơ
                                của bạn), hoặc các sản phẩm, dịch vụ, sự kiện hoặc khuyến mãi của các đối tác, nhà tài
                                trợ và quảng cáo của Otto. Ví dụ, chúng tôi có thể:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    gửi cho bạn các ưu đãi đặc biệt có thể phù hợp hoặc liên quan đến tất cả các dịch vụ
                                    của Otto;
                                </li>
                                <li>
                                    gửi cho bạn cảnh báo, thông báo, cập nhật, bản quảng cáo, đặc quyền đặc biệt, lời
                                    chúc mừng lễ; và
                                </li>
                                <li>
                                    thông báo, mời và quản lý sự tham gia của bạn trong các sự kiện hoặc hoạt động của
                                    chúng tôi.
                                </li>
                            </ul>
                            <p>
                                Chúng tôi có thể truyền đạt thông tin tiếp thị này cho bạn qua nhiều phương tiện khác
                                nhau, nếu có (bao gồm cả qua tin nhắn SMS, ứng dụng trò chuyện (ví dụ: WhatsApp,
                                Telegram, LINE, Viber, WeChat, Zalo)), thông báo đẩy, cuộc gọi và qua email.
                                <br />
                                Nếu bạn muốn hủy đăng ký nhận các thông tin tiếp thị, vui lòng nhấp vào liên kết hủy
                                đăng ký trong email hoặc tin nhắn tương ứng. Hoặc bạn cũng có thể cập nhật ưu đãi của
                                mình trong cài đặt ứng dụng của chúng tôi. Lưu ý rằng trong khi bạn có thể chọn không
                                nhận các thông tin tiếp thị hoặc khuyến mãi, Grab vẫn có thể gửi cho bạn các tin nhắn
                                liên quan đến dịch vụ (như thông tin về giao dịch, phần thưởng, v.v.).
                            </p>
                            <h3 className="font-bold my-10">Nội dung do Người Dùng Công bố</h3>
                            <p>
                                Nếu bạn công bố nội dung (như đánh giá hoặc bình luận) trên nền tảng của Grab, nó sẽ
                                được hiển thị công khai cùng với dữ liệu cá nhân của bạn (như tên hồ sơ, hình ảnh đã tải
                                lên), và các thông tin khác mà bạn chọn bao gồm trong nội dung của mình. Để biết thêm
                                thông tin, xem Hướng dẫn Đánh giá của chúng tôi.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">III. TIẾN HÀNH TIẾT LỘ DỮ LIỆU CÁ NHÂN</h3>
                            <h3 className="my-10">
                                Chúng tôi cần chia sẻ Dữ liệu Cá nhân với các bên khác nhau cho các Mục đích. Những bên
                                này bao gồm:
                            </h3>
                            <p className="font-bold">Các người dùng khác</p>
                            <p>Ví dụ:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    Nếu bạn là hành khách của đối tác lái xe, chúng tôi có thể chia sẻ tên người dùng,
                                    địa điểm đón và địa điểm trả khách của bạn với đối tác lái xe đang thực hiện yêu cầu
                                    Dịch vụ của bạn;
                                </li>
                                <li>
                                    Nếu bạn là đối tác lái xe, chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với hành
                                    khách của bạn bao gồm tên và ảnh của bạn; thông tin về loại xe, mẫu xe, biển số, địa
                                    điểm và xếp hạng trung bình của bạn;
                                </li>
                                <li>
                                    Nếu bạn là đối tác giao hàng, chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với
                                    người mua và người dùng được chọn của bạn, bao gồm tên và ảnh của bạn; thông tin về
                                    loại xe, mẫu xe, địa điểm và xếp hạng trung bình của bạn;
                                </li>
                                <li>
                                    Nếu bạn sử dụng Dịch vụ Otto Express của chúng tôi, chúng tôi có thể chia sẻ Dữ liệu
                                    Cá nhân của bạn với người nhận hàng của bạn, và ngược lại, cũng như với đối tác giao
                                    hàng chịu trách nhiệm thực hiện yêu cầu Dịch vụ của bạn; và
                                </li>
                                <li>
                                    Nếu bạn sử dụng ứng dụng trò chuyện của chúng tôi, chúng tôi có thể chia sẻ số điện
                                    thoại di động của bạn và tên đã đăng ký Otto của bạn với các bên khác trong cuộc trò
                                    chuyện của bạn.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Với bên thứ ba</h3>
                            <h3 className="font-bold my-10">
                                Với bên thứ ba liên quan đến việc sử dụng Dịch vụ Otto của bạn
                            </h3>
                            <p>Ví dụ:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    Nếu bạn là hành khách của đối tác lái xe, chúng tôi có thể chia sẻ vị trí của phương
                                    tiện và tên của tài xế và/hoặc bạn với bên thứ ba khi bạn sử dụng tính năng "Chia Sẻ
                                    Chuyến đi của Tôi" hoặc kích hoạt Nút Khẩn cấp.
                                </li>
                                <li>
                                    Nếu bạn là đối tác lái xe, chúng tôi có thể tiết lộ số biển xe hoặc các dữ liệu cá
                                    nhân khác cho công ty bảo hiểm và/hoặc cho hành khách của bạn để xử lý đòi hỏi bồi
                                    thường bảo hiểm.
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Với chủ sở hữu của tài khoản Grab bạn có thể sử dụng</h3>
                            <p>
                                Ví dụ, doanh nghiệp của bạn có thể nhận được dữ liệu chuyến đi (đánh dấu là chuyến đi
                                công việc) khi bạn sử dụng tài khoản Otto for Business của doanh nghiệp.
                            </p>
                            <h3 className="font-bold my-10">Với các công ty con và chi nhánh</h3>
                            <p>
                                Chúng tôi chia sẻ Dữ liệu Cá nhân với các công ty con, các công ty liên kết, các đơn vị
                                kiểm soát chung và các công ty chi nhánh. Các công ty trong nhóm Otto chia sẻ tài
                                nguyên, chẳng hạn như cơ sở hạ tầng và công nghệ, với nhau để cung cấp cho bạn trải
                                nghiệm nhất quán, liên quan và an toàn khi bạn sử dụng Dịch vụ của chúng tôi, và cho các
                                Mục đích đã nêu trong Thông báo Quyền riêng tư này. Ví dụ, chúng tôi làm điều này để làm
                                cho trải nghiệm của bạn trở nên mượt mà hơn, chẳng hạn như tự động điền thông tin đăng
                                ký của bạn từ việc sử dụng một Dịch vụ Otto khi bạn đăng ký sử dụng Dịch vụ Otto mới.
                            </p>
                            <h3 className="font-bold my-10">
                                Với các nhà cung cấp dịch vụ và đối tác kinh doanh của Otto
                            </h3>
                            <p className=" my-10">
                                Chúng tôi có thể cung cấp Dữ liệu Cá nhân cho các nhà cung cấp dịch vụ, nhà tư vấn, đối
                                tác tiếp thị, công ty nghiên cứu và các nhà cung cấp dịch vụ hoặc đối tác kinh doanh
                                khác.
                            </p>
                            <p>Điều này bao gồm:</p>
                            <ul className="list-disc ml-7">
                                <li>những người xử lý thanh toán và người hỗ trợ thanh toán;</li>
                                <li>các đơn vị thu nợ;</li>
                                <li>
                                    các cơ quan thông tin tín dụng, các công ty đánh giá tín dụng thay thế và bất kỳ tổ
                                    chức báo cáo tín dụng nào khác;
                                </li>
                                <li>các nhà cung cấp dịch vụ kiểm tra lịch sử và chống rửa tiền;</li>
                                <li>các nhà cung cấp lưu trữ đám mây;</li>
                                <li>các đối tác và nhà cung cấp nền tảng quảng cáo và tiếp thị;</li>
                                <li>các nhà cung cấp dịch vụ phân tích dữ liệu;</li>
                                <li>
                                    đối tác nghiên cứu, bao gồm những người thực hiện khảo sát hoặc dự án nghiên cứu
                                    phối hợp với Otto hoặc thay mặt cho Otto;
                                </li>
                                <li>các đối tác viện đào tạo và giáo dục;</li>
                                <li>các đối tác đội và đối tác thương mại;</li>
                                <li>các đối tác bảo hiểm và tài chính;</li>
                                <li>
                                    các trung gian bên thứ ba tham gia vào quản lý đầu tư quỹ, chẳng hạn như các nhà môi
                                    giới, quản lý tài sản và người giữ tài sản;
                                </li>
                                <li>các nhà cung cấp dịch vụ thực hiện dịch vụ xác minh danh tính; và</li>
                                <li>các đối tác, nhà cung cấp hoặc nhà cung ứng phương tiện thứ ba.</li>
                            </ul>
                            <p className="mt-4">Ví dụ:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    Nếu bạn yêu cầu một dịch vụ thông qua một đối tác của Otto hoặc sử dụng một ưu đãi
                                    do một đối tác của Otto cung cấp, Otto có thể chia sẻ Dữ liệu Cá nhân của bạn với
                                    đối tác Otto đó.
                                </li>
                                <li>
                                    Nếu bạn đã thuê một phương tiện từ các đối tác đội mà chúng tôi đề xuất để lái xe
                                    với Otto, chúng tôi có thể chia sẻ thông tin liên quan (ví dụ: trạng thái tài khoản
                                    của bạn, ưu đãi/ khuyến mãi áp dụng, thống kê chuyến đi của bạn) cho những nỗ lực
                                    cộng tác (ví dụ: tạo ưu đãi và lợi ích mới cho bạn hoặc cải thiện nguồn cung đối tác
                                    lái xe).
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">
                                Với các cố vấn pháp lý của chúng tôi và cơ quan chính phủ
                            </h3>
                            <p>
                                Chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với các cố vấn pháp lý của chúng tôi,
                                các quan chức thi hành pháp luật, các cơ quan chính phủ và các bên thứ ba khác. Điều này
                                có thể xảy ra để thực hiện các Mục đích pháp lý (đã đề cập ở trên), hoặc trong bất kỳ
                                tình huống nào sau đây:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    khi cần phải đáp ứng cho tình trạng khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc an
                                    toàn của một người; hoặc
                                </li>
                                <li>
                                    khi cần thiết vì lợi ích công cộng (ví dụ: trong một tình huống khẩn cấp về sức khỏe
                                    cộng đồng và mục đích theo dõi tiếp xúc để bảo vệ cộng đồng của chúng ta).
                                </li>
                            </ul>
                            <h3 className="font-bold my-10">Chuyển nhượng doanh nghiệp</h3>
                            <p>
                                Chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với các bên khác, liên quan đến bất kỳ
                                giao dịch nào như mua bán, sáp nhập, liên doanh, sáp nhập, tái cấu trúc, tài trợ hoặc
                                bất kỳ loại giao dịch kinh doanh nào khác. Dữ liệu Cá nhân của bạn tuy nhiên sẽ tiếp tục
                                chịu sự ràng buộc của những cam kết được thực hiện trong bất kỳ Biểu mẫu Quyền riêng tư
                                trước đây nào mà bạn đã đồng ý.
                            </p>
                        </div>
                        
                        <div className="mt-5">
                            <h3 className="font-bold">IV. BẢO QUẢN DỮ LIỆU CÁ NHÂN</h3>
                            <p className="my-10">
                                Chúng tôi lưu giữ Dữ liệu Cá nhân của bạn trong khoảng thời gian cần thiết để thực hiện
                                các Mục đích được đề cập trong Biểu mẫu Quyền riêng tư này, trừ khi có yêu cầu hoặc cho
                                phép lưu giữ lâu hơn theo quy định của luật pháp. Khi Dữ liệu Cá nhân của bạn không còn
                                cần thiết cho Dịch vụ hoặc Mục đích, hoặc chúng tôi không còn mục đích pháp lý hoặc kinh
                                doanh nào để lưu giữ Dữ liệu Cá nhân của bạn, chúng tôi sẽ thực hiện các bước để xóa,
                                hủy, ẩn danh hoặc ngăn chặn quyền truy cập hoặc sử dụng Dữ liệu Cá nhân đó cho bất kỳ
                                mục đích nào khác ngoài việc tuân thủ Biểu mẫu Quyền riêng tư này hoặc cho mục đích an
                                toàn, bảo mật, ngăn chặn và phát hiện gian lận, tuân thủ theo yêu cầu của luật pháp hiện
                                hành.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold">V. CHUYỂN GIAO QUỐC TẾ DỮ LIỆU CÁ NHÂN    </h3>
                            <p className="mt-4">
                                Dữ liệu Cá nhân của bạn có thể được chuyển từ quốc gia, bang và thành phố (“Quê hương”)
                                mà bạn đang sử dụng Dịch vụ đến một quốc gia, bang và thành phố khác (“Quốc gia thay
                                thế”).
                                <br />
                                Khi chúng tôi chuyển Dữ liệu Cá nhân của bạn từ Quê hương của bạn đến Quốc gia thay thế,
                                chúng tôi sẽ tuân thủ các nghĩa vụ pháp lý và quy định của chính phủ liên quan đến Dữ
                                liệu Cá nhân của bạn, bao gồm việc có cơ sở hợp pháp để chuyển Dữ liệu Cá nhân và thiết
                                lập các biện pháp bảo vệ thích hợp để đảm bảo mức độ bảo vệ đủ cho Dữ liệu Cá nhân.
                                Chúng tôi cũng sẽ đảm bảo rằng bên nhận ở Quốc gia thay thế có trách nhiệm bảo vệ Dữ
                                liệu Cá nhân của bạn theo một mức bảo vệ tương đương với mức bảo vệ theo quy định của
                                luật pháp áp dụng.
                                <br />
                                Cơ sở pháp của chúng tôi sẽ là sự đồng ý (ví dụ: chúng tôi có thể yêu cầu sự đồng ý của
                                bạn để chuyển Dữ liệu Cá nhân từ Quê hương của bạn đến Quốc gia thay thế khi bạn cung
                                cấp Dữ liệu Cá nhân của mình) hoặc một trong những biện pháp bảo vệ được phép theo luật
                                pháp.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold ">VI. COOKIES AND ADVERTISING ON THIRD PARTY PLATFORMS</h3>
                            <h3 className="font-bold my-10 ">Cookies</h3>
                            <p>
                                Otto, và các bên thứ ba mà chúng tôi hợp tác, có thể sử dụng cookies, web beacons, tags,
                                scripts, đối tượng chia sẻ cục bộ như HTML5, bảng điều chỉnh quảng cáo và các công nghệ
                                tương tự (thường gọi là “Cookies”) trong quá trình bạn sử dụng các Trang web và Ứng dụng
                                của chúng tôi, có thể là cố định hoặc chỉ lưu trữ trong một phiên cá nhân trên trình
                                duyệt hoặc thiết bị của bạn.
                                <br />
                                Cookies có thể có các định danh duy nhất và tồn tại, ngoại trừ các nơi khác, trên máy
                                tính hoặc thiết bị di động của bạn, trong các email chúng tôi gửi cho bạn và trên các
                                trang web của chúng tôi. Cookies có thể được sử dụng cho các mục đích khác nhau như:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>
                                    cung cấp các chức năng cơ bản và quan trọng cho việc sử dụng Trang web, Dịch vụ hoặc
                                    Ứng dụng của chúng tôi;
                                </li>
                                <li>xác thực bạn hoặc nhớ các thiết lập và tùy chọn người dùng của bạn;</li>
                                <li>
                                    phân phối và đo lường hiệu quả của các chiến dịch quảng cáo, như đo lường số lượt
                                    xem hoặc số lượt nhấp chuột;
                                </li>
                                <li>phân tích lưu lượng và xu hướng trang web;</li>
                                <li>
                                    phục vụ quảng cáo có liên quan trên các ứng dụng và trang web khác thuộc sở hữu của
                                    các công ty khác (có thể là của chúng tôi hoặc của các nhà quảng cáo khác); và
                                </li>
                                <li>
                                    nâng cao giao diện và trải nghiệm người dùng bằng cách hiểu chung về hành vi trực
                                    tuyến và sở thích của người dùng tương tác với Trang web, Dịch vụ hoặc Ứng dụng của
                                    chúng tôi.
                                </li>
                            </ul>
                            <p>
                                Otto có thể cho phép bên thứ ba sử dụng Cookies trên các Trang web và Ứng dụng để thu
                                thập cùng loại Dữ liệu Cá nhân cho cùng mục đích mà Otto thực hiện. Bên thứ ba có thể
                                liên kết Dữ liệu Cá nhân họ thu thập được với Dữ liệu Cá nhân khác họ có về bạn từ các
                                nguồn khác. Chúng tôi không nhất thiết có quyền truy cập hoặc kiểm soát Cookies mà họ sử
                                dụng.
                            </p>
                            <h3 className="font-bold my-10 ">Tắt việc thu thập cookies trên trang web của chúng tôi</h3>
                            <p>
                                Bạn có quyền lựa chọn tắt, chặn hoặc vô hiệu hóa cookies. Tuy nhiên, lưu ý rằng việc từ
                                chối hoặc loại bỏ một số cookies có thể ảnh hưởng đến tính sẵn có, chức năng hoặc sử
                                dụng của Trang web, Dịch vụ hoặc Ứng dụng của chúng tôi.
                                <br />
                                Bạn có thể điều chỉnh cài đặt trình duyệt internet của mình để tắt, chặn hoặc vô hiệu
                                hóa cookies, xóa lịch sử duyệt web của bạn hoặc xóa bộ nhớ đệm từ trình duyệt internet
                                của bạn. Các cài đặt có thể nằm trong phần “Lịch sử”, “Tùy chọn”, “Cài đặt” hoặc “Quyền
                                riêng tư” của trình duyệt internet của bạn, hoặc bạn có thể tham khảo phần trợ giúp của
                                trình duyệt internet của bạn để biết thêm thông tin.
                            </p>
                            <h3 className="font-bold my-10 ">
                                Tắt hiển thị Quảng cáo có Đối tượng qua Ứng dụng của chúng tôi
                            </h3>
                            <p>
                                Chúng tôi có thể hỗ trợ hiển thị quảng cáo có đối tượng trên các nền tảng khác, chẳng
                                hạn như trên các nền tảng trực tuyến khác mà bạn có thể là người dùng.
                                <br />
                                Bạn có thể giới hạn việc chúng tôi sử dụng Dữ liệu Cá nhân của bạn để hiển thị quảng cáo
                                có đối tượng thay mặt cho các nhà quảng cáo của chúng tôi bằng cách điều chỉnh ưa thích
                                thông qua Ứng dụng. Tùy thuộc vào loại điện thoại và phần mềm điều hành mà bạn đang sử
                                dụng, bạn có thể có tùy chọn để tắt việc sử dụng các định danh di động của chúng tôi
                                (chẳng hạn như IDFA của Apple hoặc GAID của Google) và khả năng theo dõi bạn qua các ứng
                                dụng và trang web thuộc sở hữu của các công ty khác bằng cách điều chỉnh cài đặt có sẵn
                                trên thiết bị di động của bạn. Bằng cách điều chỉnh cài đặt trên thiết bị của bạn, bạn
                                có thể thường xuyên thiết lập lại, xóa, giới hạn và/hoặc hạn chế việc sử dụng các định
                                danh di động như vậy.
                                <br />
                                Mặc dù chúng tôi sẽ không sử dụng Dữ liệu Cá nhân của bạn để hiển thị quảng cáo có đối
                                tượng trên các nền tảng khác, bạn vẫn có thể thấy quảng cáo thông thường xuất hiện có
                                liên quan đối với bạn. Điều này có thể xảy ra vì chúng tôi đã hợp tác với các nhà quảng
                                cáo/thành viên thứ ba để hiển thị quảng cáo cho người dùng có đặc điểm hoặc cấu trúc dân
                                số cụ thể mà họ có thể thấy liên quan. Bạn có thể giảm thiểu việc xem các quảng cáo này
                                bằng cách quản lý tùy chọn của mình trên các nền tảng này.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">VII. BẢO VỆ DỮ LIỆU CÁ NHÂN</h3>
                            <p>
                                Chúng tôi sẽ thực hiện các biện pháp pháp lý, tổ chức và kỹ thuật hợp lý để đảm bảo rằng
                                Dữ liệu Cá nhân của bạn được bảo vệ. Điều này bao gồm các biện pháp để ngăn chặn Dữ liệu
                                Cá nhân bị mất, hoặc sử dụng hoặc truy cập một cách không được ủy quyền. Chúng tôi hạn
                                chế quyền truy cập vào Dữ liệu Cá nhân của bạn chỉ cho những nhân viên cần biết. Những
                                người xử lý Dữ liệu Cá nhân của bạn chỉ sẽ làm như vậy theo một cách được ủy quyền và
                                được yêu cầu xử lý thông tin của bạn một cách bảo mật.
                                <br />
                                Tuy nhiên, vui lòng hiểu rằng việc truyền thông tin qua internet không hoàn toàn an
                                toàn. Mặc dù chúng tôi sẽ làm hết sức mình để bảo vệ Dữ liệu Cá nhân của bạn, nhưng
                                chúng tôi không thể đảm bảo an ninh của Dữ liệu Cá nhân của bạn được truyền qua bất kỳ
                                phương tiện trực tuyến nào, do đó, mọi truyền thông vẫn có rủi ro của bạn.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">VIII. QUYỀN CỦA BẠN ĐỐI VỚI DỮ LIỆU CÁ NHÂN CỦA BẠN</h3>
                            <p>Theo quy định của các luật và quy định hiện hành, bạn có thể được quyền:</p>
                            <ul className="list-disc ml-7">
                                <li>
                                    yêu cầu thông tin về việc xử lý Dữ liệu Cá nhân của bạn và được cung cấp một bản sao
                                    của nó;
                                </li>
                                <li>trong một số trường hợp, yêu cầu sửa đổi và/hoặc xóa Dữ liệu Cá nhân của bạn;</li>
                                <li>
                                    trong một số trường hợp, yêu cầu hạn chế hoặc phản đối việc xử lý Dữ liệu Cá nhân
                                    của bạn;
                                </li>
                                <li>
                                    rút lại sự đồng ý của bạn với việc xử lý Dữ liệu Cá nhân của bạn (nếu chúng tôi xử
                                    lý Dữ liệu Cá nhân của bạn dựa trên sự đồng ý của bạn);
                                </li>
                                <li>
                                    yêu cầu nhận hoặc chuyển giao cho một tổ chức khác, dưới dạng có thể đọc được bằng
                                    máy, Dữ liệu Cá nhân mà bạn đã cung cấp cho chúng tôi khi chúng tôi sử dụng Dữ liệu
                                    Cá nhân của bạn dựa trên sự đồng ý hoặc thực hiện một hợp đồng; và
                                </li>
                                <li>
                                    khiếu nại cho cơ quan bảo vệ dữ liệu liên quan nếu quyền riêng tư của bạn bị vi
                                    phạm, hoặc nếu bạn gặp tổn thất do việc xử lý Dữ liệu Cá nhân của bạn vi phạm pháp
                                    luật.
                                </li>
                            </ul>

                            <p>
                                Khi bạn có khả năng chia sẻ Dữ liệu Cá nhân của bạn với chúng tôi, bạn luôn có thể chọn
                                không làm điều đó. Nếu chúng tôi đã yêu cầu sự đồng ý của bạn để xử lý và sau đó bạn
                                chọn rút lại, chúng tôi sẽ tôn trọng quyết định đó theo các nghĩa vụ pháp lý của chúng
                                tôi.
                                <br />
                                Tuy nhiên, việc chọn không chia sẻ Dữ liệu Cá nhân của bạn với chúng tôi, rút lại sự
                                đồng ý của bạn đối với việc chúng tôi sử dụng hoặc đồng ý với Thông báo Quyền riêng tư
                                của chúng tôi có thể dẫn đến việc chúng tôi không thể thực hiện các hành động cần thiết
                                để đạt được mục đích xử lý mà chúng tôi mô tả trong Phần II (Sử dụng Dữ liệu Cá nhân)
                                hoặc bạn không thể sử dụng Apps hoặc Dịch vụ.
                                <br />
                                Sau khi bạn đã chọn rút lại sự đồng ý, chúng tôi có thể tiếp tục xử lý Dữ liệu Cá nhân
                                của bạn trong phạm vi được yêu cầu hoặc được phép theo luật và quy định áp dụng.
                                <br />
                                Chúng tôi sẽ kiểm tra và xác minh tất cả các yêu cầu trước. Để xác minh danh tính
                                và/hoặc quyền lực để đưa ra yêu cầu, chúng tôi có thể yêu cầu bạn cung cấp thông tin
                                hoặc tài liệu hỗ trợ để xác nhận yêu cầu. Sau khi xác minh, chúng tôi sẽ thực hiện yêu
                                cầu của bạn trong khoảng thời gian quy định bởi các luật lệ áp dụng.
                            </p>
                        </div>

                        <div className="mt-5">
                            <h3 className="font-bold mb-10">IX. SỬA ĐỔI VÀ CẬP NHẬT</h3>
                            <p className="">
                                Otto có thể sửa đổi, cập nhật hoặc sửa đổi các điều khoản trong Thông báo Quyền riêng tư
                                này bất kỳ lúc nào. Những sửa đổi này sẽ được thông báo cho bạn thông qua Ứng dụng
                                và/hoặc các phương tiện phù hợp khác ít nhất năm (5) ngày làm việc trước ngày có hiệu
                                lực. Phiên bản cập nhật sẽ được đăng trên trang web <strong>http://www.Otto.com</strong>
                                . Bạn có trách nhiệm xem xét Thông báo Quyền riêng tư thường xuyên. Việc sử dụng tiếp
                                tục của bạn đối với Ứng dụng, Trang web hoặc Dịch vụ, hoặc tiếp tục giao tiếp hoặc tương
                                tác với Grab sau ngày có hiệu lực của các sửa đổi, có hoặc không được bạn xem xét, sẽ
                                được xem là bạn đồng ý bị ràng buộc bởi những sửa đổi đó.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h3 className="font-bold mb-10">X. HOW TO CONTACT US</h3>
                            <p className="">
                                Nếu bạn có bất kỳ thắc mắc nào về Thông báo này hoặc muốn thực hiện quyền của mình được
                                nêu trong Thông báo này, vui lòng điền vào biểu mẫu sau hoặc gửi thư của bạn đến Quan
                                chức Bảo vệ Dữ liệu của chúng tôi:
                            </p>
                            <ul className="list-disc ml-7">
                                <li>...</li>
                                <li>...</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            )}
             <Footer isChangeLanguage={isChangeLanguage} />
        </div>
    )
}

export default Privacy
